import { Component, forwardRef, ViewChild } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatSelect } from '@angular/material';
import { PayerService } from 'src/app/template/payer/payer.service';

@Component({
  selector: "app-payer",
  templateUrl: "./payer.component.html",
  styleUrls: ["./payer.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PayerComponent),
      multi: true
    },
    PayerService
  ]
})
export class PayerComponent {
  @ViewChild("select", { static: true }) select: MatSelect;
  public payers = [];

  constructor(payerService: PayerService) {
    payerService.find().then(payers => {
      this.payers = (payers as any[]).filter(x => !x.archived ); //Filter in array and not in find because it didn't work!
      this.payers.sort((a, b) => a.name.localeCompare(b.name));
    });
  }

  writeValue(obj: any): void {
    this.select.value = obj;
  }
  registerOnChange(fn: any): void {
    this.select.registerOnChange(fn);
  }
  registerOnTouched(fn: any): void {
    this.select.registerOnTouched(fn);
  }
  setDisabledState?(isDisabled: boolean): void {
    this.select.disabled = isDisabled;
  }
}
