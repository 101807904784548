import { HttpHeaders } from '@angular/common/http';
import authentication from '@feathersjs/authentication-client';
import feathers from '@feathersjs/feathers';
import restClient from '@feathersjs/rest-client';
import { environment } from 'src/environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class FeathersService {
    constructor(httpClient) {
        const options = { HttpHeaders };
        this._app = feathers();
        this._app.configure(restClient(environment.apiUrl).angularHttpClient(httpClient, options));
        this._app.configure(authentication());
    }
    get app() {
        return this._app;
    }
}
FeathersService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function FeathersService_Factory() { return new FeathersService(i0.ɵɵinject(i1.HttpClient)); }, token: FeathersService, providedIn: "root" });
