import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from 'src/app/login/login.component';
import { NoAuthGuard } from 'src/app/login/no-auth.guard';
import { AuthGuard } from 'src/app/shared/guards/auth.guard';
import { TemplateListComponent } from 'src/app/template/template-list/template-list.component';

const routes: Routes = [
  {
    path: '',
    component: TemplateListComponent,
    canActivate: [AuthGuard],
    pathMatch: 'full',
  },
  { path: 'login', component: LoginComponent, canActivate: [NoAuthGuard] },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { enableTracing: false, useHash: true })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
