import { Component, Input, OnChanges } from '@angular/core';

import { AlbertaMatchField } from '../../../shared/models/alberta-match-field.model';
import { MatchField } from '../../../shared/models/match-field.model';
import { Template } from '../../../shared/models/template.model';
import {
  AlbertaInstitutionsMatchFields,
  AlbertaPatientsMatchFields,
} from '../../../shared/services/alberta-match-fields.service';
import { MatchService } from '../../../shared/services/match.service';

@Component({
  selector: 'app-template-match',
  templateUrl: './template-match.component.html',
  styleUrls: ['./template-match.component.scss'],
})
export class TemplateMatchComponent implements OnChanges {
  @Input() public template: Template;
  public showSpinner: boolean;
  public templateNodes;

  public matchFields: MatchField[];
  public albertaMatchFields = [];

  constructor(private _matchService: MatchService) {}

  async ngOnChanges() {
    this.matchFields = undefined;
    this.templateNodes = undefined;
    if (this.template && this.template.entityTypes.length === 1 && this.template.entityTypes[0] === 'patient') {
      this.albertaMatchFields = AlbertaPatientsMatchFields;
    } else {
      this.albertaMatchFields = AlbertaInstitutionsMatchFields;
    }
    this.albertaMatchFields.sort((a, b) => a.title.localeCompare(b.title));
    if (this.template) {
      this.showSpinner = true;
      this.templateNodes = this.buildTemplateNodesArray();
      if ((this.template as any).match) {
        const match = await this._matchService.get(this.template._id);
        if (match) {
          this.matchFields = match.fields;
        }
      }
      this.showSpinner = false;
    }
  }
  compareFn(c1: AlbertaMatchField, c2: AlbertaMatchField): boolean {
    return c1 && c2 ? c1._id === c2._id : c1 === c2;
  }
  addMatchField() {
    this.matchFields = this.matchFields || [];
    this.matchFields.push({ swodoc: undefined, alberta: undefined });
  }

  removeMatchField(field: MatchField) {
    this.matchFields = this.matchFields.filter((f) => f !== field);
  }

  itemDisabled(id: string) {
    if (this.matchFields && this.matchFields.length > 0) {
      return this.matchFields.find((f) => f.swodoc === id) !== undefined;
    }
  }

  async save() {
    if (this.matchFields) {
      if (this.matchFields.length === 0) {
        return await this._matchService.remove(this.template._id);
      }
      if ((this.template as any).match) {
        await this._matchService.patch(this.template._id, {
          fields: this.matchFields,
        });
      } else {
        await this._matchService.create({
          _id: this.template._id,
          fields: this.matchFields,
        });
      }
    }
  }

  private buildTemplateNodesArray() {
    const templateNode = { template: this.template.title, sections: [] };
    (this.template as any).templateNodes.forEach((section) => {
      const templateNodeSection = { title: section.title, nodes: [] };
      this.buildTemplateNodesArrayRec(section, templateNodeSection);
      templateNode.sections.push(templateNodeSection);
    });
    return templateNode;
  }

  private buildTemplateNodesArrayRec(node, templateNodeSection) {
    if (node.children && node.children !== null) {
      this.buildTemplateNodesArrayRec(node.children, templateNodeSection);
    } else {
      node.forEach((child) => {
        if (child.nodeType_Id === 'text' || child.nodeType_Id === 'textarea' || child.nodeType_Id === 'date') {
          templateNodeSection.nodes.push({
            _id: child._id,
            title: child.title !== '' ? child.title : '- no name -',
            name: child._name,
            type: child.nodeType_Id,
          });
        }
        if (child.children && child.children !== null && child.children.length) {
          this.buildTemplateNodesArrayRec(child.children, templateNodeSection);
        }
      });
    }
  }
}
