import { Injectable } from '@angular/core';
import { BaseService } from 'src/app/shared/services/base.service';
import { FeathersService } from 'src/app/shared/services/feathers.service';

@Injectable()
export class PayerService extends BaseService<any> {
  constructor(feathersService: FeathersService) {
    super("payer", feathersService.app);
  }
}
