<mat-menu #appMenu="matMenu">
  <button mat-menu-item (click)="toggleDraft()" [disabled]="template.archived">
    <mat-icon>{{ template.draft ? 'add_to_home_screen' : 'drafts' }}</mat-icon
    >{{ template.draft ? 'Live' : 'Entwurf' }}
  </button>
  <mat-divider></mat-divider>
  <button mat-menu-item (click)="edit()"><mat-icon>edit</mat-icon>Bearbeiten</button>
  <button mat-menu-item (click)="duplicate()"><mat-icon>file_copy</mat-icon>Duplizieren</button>
  <button mat-menu-item (click)="toggleArchived()">
    <mat-icon>{{ template.archived ? 'restore_from_trash' : 'delete' }}</mat-icon
    >{{ template.archived ? 'Wiederherstellen' : 'Archivieren' }}
  </button>
</mat-menu>

<button mat-icon-button #menuTrigger="matMenuTrigger" [matMenuTriggerFor]="appMenu" (click)="openMenu($event)">
  <mat-icon>more_vert</mat-icon>
</button>
