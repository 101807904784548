import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog, MatSnackBar } from '@angular/material';
import { take } from 'rxjs/operators';

import { DeleteDialogService } from '../../shared/layout/delete-dialog/delete-dialog.service';
import { Template } from '../../shared/models/template.model';
import { CopyTemplateDialogComponent } from '../copy-template-dialog/copy-template-dialog.component';
import { CreateEditTemplateDialogComponent } from '../create-edit-template-dialog/create-edit-template-dialog.component';
import { TemplateService } from '../template.service';

@Component({
  selector: 'app-template-more-menu',
  templateUrl: './template-more-menu.component.html',
  styleUrls: ['./template-more-menu.component.scss'],
})
export class TemplateMoreMenuComponent implements OnInit {
  @Input() template: Template;
  @Output() update = new EventEmitter<Template>();
  @Output() archivedToggled = new EventEmitter<boolean>();

  constructor(
    private _dialog: MatDialog,
    private _templatService: TemplateService,
    private _deleteDialog: DeleteDialogService,
    private _snackBar: MatSnackBar
  ) {}

  ngOnInit() {}

  openMenu(event: Event) {
    event.stopPropagation();
  }

  duplicate() {
    this._dialog
      .open(CopyTemplateDialogComponent, {
        width: '400px',
        maxHeight: '800px',
        data: { template: { ...this.template } },
      })
      .afterClosed()
      .subscribe(result => {
        if (result) {
          this.update.emit(result._id);
        }
      });
  }

  edit() {
    this._dialog
      .open(CreateEditTemplateDialogComponent, {
        width: '400px',
        maxHeight: '800px',
        data: { template: { ...this.template } },
      })
      .afterClosed()
      .subscribe(result => {
        if (result) {
          this.update.emit(result._id);
        }
      });
  }

  async toggleDraft() {
    const draft = !this.template.draft;
    await this._templatService.patch(this.template._id, { draft });
    this.template.draft = draft;
  }

  async toggleArchived() {
    const displayErrorToast = (message: string) => {
      this._snackBar.open(message, undefined, {
        duration: 3000,
      });
    };
    return new Promise(async resolve => {
      if (!this.template.archived) {
        this._deleteDialog.show('die Vorlage', this.template.title, false);
        this._deleteDialog.afterDelConfirm.pipe(take(1)).subscribe(async () => {
          const deletedTemplate = await this._templatService
            .patch(this.template._id, { archived: true })
            .catch(displayErrorToast);
          if (deletedTemplate) {
            this.archivedToggled.emit(true);
          }
        });
      } else {
        const deletedTemplate = await this._templatService
          .patch(this.template._id, { archived: false, draft: true })
          .catch(displayErrorToast);
        if (deletedTemplate) {
          this.archivedToggled.emit(false);
        }
      }
    });
  }
}
