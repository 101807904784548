import { ITemplate } from '@pia/pia.shared';
import { XSDGenerator } from '@pia/pia.swodoc.document-generator';
import { Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, Output, ViewChild } from '@angular/core';
import { MatSidenav, MatTabGroup } from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';
import { saveAs } from 'file-saver';
import { Subscription } from 'rxjs';
import { ReportService } from 'src/app/shared/services/report.service';
import { MetadataGenerator } from 'src/app/template/template-detail/metadata-generator';
import { TemplateService } from 'src/app/template/template.service';
import { environment } from 'src/environments/environment';

import { Template } from '../../shared/models/template.model';
import { AuthService } from '../../shared/services/auth.service';
import { FullscreenService } from '../../shared/services/fullscreen.service';
import { TemplateMatchComponent } from './template-match/template-match.component';
import { TemplateReportComponent } from './template-report/template-report.component';

@Component({
  selector: 'app-template-detail',
  templateUrl: './template-detail.component.html',
  styleUrls: ['./template-detail.component.scss'],
})
export class TemplateDetailComponent implements OnDestroy, OnChanges {
  @ViewChild('tabs', { static: true }) tabs: MatTabGroup;
  @ViewChild('match', { static: true }) matchComponent: TemplateMatchComponent;
  @ViewChild('report', { static: true })
  private reportComponent: TemplateReportComponent;
  @ViewChild('swodocIframe', { static: true })
  private _iframe: ElementRef;
  @Input() template: Template;
  @Input() sidenav: MatSidenav;
  @Output() updated = new EventEmitter();
  public fullscreen: boolean;
  public templateUrl = 'about:blank';

  private _ngUnsusbscribe = new Subscription();

  constructor(
    public fullscreenService: FullscreenService,
    public sanitizer: DomSanitizer,
    private _auth: AuthService,
    private _templateService: TemplateService,
    private readonly _reportService: ReportService
  ) {}

  async ngOnChanges() {
    if (!this.template) {
      return;
    }
    const initTab = (index: number) => {
      switch (index) {
        case 0:
          this.initIframe();
          break;
        case 2:
          setTimeout(() => {
            this.reportComponent.renderDesigner();
          }, 1);
          break;
      }
    };

    this._ngUnsusbscribe = new Subscription();
    this._ngUnsusbscribe.add(
      this.tabs.selectedIndexChange.subscribe(async (index) => {
        this.save();
        initTab(index);
      })
    );
    this._ngUnsusbscribe.add(
      this.fullscreenService.fullscreen.subscribe((fullscreen) => (this.fullscreen = fullscreen))
    );

    initTab(this.tabs.selectedIndex);
  }

  private async initIframe() {
    this.templateUrl = 'about:blank';
    const token = await this._auth.getToken();
    this.templateUrl = `${environment.templateBuilderUrl}/#/template/${this.template._id}`;
    if (this._iframe && this._iframe.nativeElement && this._iframe.nativeElement.contentWindow) {
      const listener = async () => {
        this._iframe.nativeElement.contentWindow.postMessage({ action: 'init', template: this.template, token }, '*');
        this._iframe.nativeElement.removeEventListener('load', listener);
      };
      this._iframe.nativeElement.addEventListener('load', listener);
    }
  }

  ngOnDestroy() {
    this._ngUnsusbscribe.unsubscribe();
    this.templateUrl = 'about:blank';
  }

  toggleFullscreen() {
    this.fullscreenService.toggle();
  }

  close() {
    this.fullscreenService.set(false);
    this.sidenav.close();
    this.tabs.selectedIndex = 0;
    this.ngOnDestroy();
  }

  async save() {
    this.reportComponent.save(this.tabs.selectedIndex);
    if (this._iframe && this._iframe.nativeElement && this._iframe.nativeElement.contentWindow) {
      this._iframe.nativeElement.contentWindow.postMessage({ action: 'saving' }, '*');
      await new Promise((resolve) => {
        const corsMessageHander = (event) => {
          if (~event.origin.indexOf(environment.templateBuilderUrl)) {
            if (event.data && event.data.action && event.data.action === 'save' && event.data.template) {
              this.template = event.data.template;
              resolve();
              window.removeEventListener('message', corsMessageHander);
            }
          } else {
            return;
          }
        };
        window.addEventListener('message', corsMessageHander);
      });
    }
    const templateToSave: any = { ...this.template };
    delete templateToSave.match;
    delete templateToSave.report;
    delete templateToSave.selected;

    templateToSave.version += 1;
    this._templateService.patch(templateToSave._id, templateToSave);
    await this.matchComponent.save();
    this.updated.emit(this.template._id);
  }

  async exportXSD() {
    const xsdGenerator = new XSDGenerator();
    try {
      const xsd = await xsdGenerator.generate(this.template as ITemplate, new MetadataGenerator());
      saveAs(new Blob([xsd], { type: 'application/xml' }), `${this.template.title}.xsd`);
    } catch (error) {
      console.error('error exporting xsd', error);
    }
  }
}
