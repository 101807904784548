import { Pipe, PipeTransform } from '@angular/core';
import { Therapies } from '../../shared/models/therapy.model';

@Pipe({
  name: 'therapy',
})
export class TherapyPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    const therapy = Therapies.complete.find(t => t.id === value);
    if (therapy) {
      return therapy.displayName;
    }
  }
}
