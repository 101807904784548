import { Pipe, PipeTransform } from '@angular/core';

const mapping = {
  nursingHome: 'Pflegeheim',
  doctor: 'Arzt',
  nursingService: 'Pflegedienst',
  hospital: 'Krankenhaus',
  pharmacy: 'Apotheke',
};

@Pipe({
  name: 'entitytype',
})
export class EntityTypePipe implements PipeTransform {
  transform(value: string[]): string {
    return value.map((institution: string) => mapping[institution]).join(', ');
  }
}
