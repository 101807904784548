import * as tslib_1 from "tslib";
import { AfterViewInit, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { groupBy } from 'lodash';
import { Subscription } from 'rxjs';
export var BasicInfoFormMode;
(function (BasicInfoFormMode) {
    BasicInfoFormMode[BasicInfoFormMode["patient"] = 0] = "patient";
    BasicInfoFormMode[BasicInfoFormMode["institution"] = 1] = "institution";
    BasicInfoFormMode[BasicInfoFormMode["careDocuments"] = 2] = "careDocuments";
    BasicInfoFormMode[BasicInfoFormMode["crossTherapy"] = 3] = "crossTherapy";
})(BasicInfoFormMode || (BasicInfoFormMode = {}));
export class TemplateBasicInfoFormComponent {
    constructor(fb, _templateService, configService, authService) {
        this.fb = fb;
        this._templateService = _templateService;
        this.configService = configService;
        this.authService = authService;
        this.templateChange = new EventEmitter();
        this.valid = new EventEmitter();
        this.templateTagOptions = [];
        this.groupingTextsOptions = [];
        this.basicInfoFormModeEnum = BasicInfoFormMode;
        this._ngUnsubscribe = new Subscription();
        this.allTemplates = [];
        this.allGroupingTexts = [];
        this.allTemplats = [];
        // Workaround for angular component issue #13870
        this.disableAnimation = true;
    }
    get showSendEmailOnComplete() {
        const user = this.authService.currentUser;
        return user && (user.email.includes('resmed') || user.email.includes('it-labs'));
    }
    ngAfterViewInit() {
        // timeout required to avoid the dreaded 'ExpressionChangedAfterItHasBeenCheckedError'
        setTimeout(() => (this.disableAnimation = false));
    }
    ngOnInit() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.template.version = Number.isNaN(+this.template.version) ? 1 : +this.template.version + 1;
            this.createTemplateForm = this.fb.group({
                title: [this.template.title, Validators.required],
                description: [this.template.description],
                therapyId: [this.template.therapyId],
                therapyTypeId: [this.template.therapyTypeId],
                dmsFieldmapping: [this.template.dmsFieldmapping],
                dmsDocumentType: [this.template.dmsDocumentType],
                dmsAccessList: [this.template.dmsAccessList],
                dmsModule: [this.template.dmsModule],
                version: [this.template.version],
                payer: [this.template.payer],
                templateTag: [this.template.templateTag],
                entityTypes: [this.template.entityTypes, Validators.required],
                groupingText: [this.template.groupingText],
                sendEmailOnComplete: [this.template.sendEmailOnComplete],
                disableReport: [this.template.disableReport],
                sendToOcr: [this.template.sendToOcr],
            });
            if (this.template._id) {
                this.valid.emit(this.createTemplateForm.valid);
            }
            this._ngUnsubscribe.add(this.createTemplateForm.valueChanges.subscribe((value) => {
                this.valid.emit(this.createTemplateForm.valid);
                this.templateChange.emit(Object.assign(this.template, value));
            }));
            this.subscribeToAutocomplete();
            yield this.loadTemplateTagAndGroupingTexts();
            yield this.loadConfig();
        });
    }
    ngOnDestroy() {
        this._ngUnsubscribe.unsubscribe();
    }
    loadTemplateTagAndGroupingTexts() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const allTemplates = (yield this._templateService.find({}));
            if (allTemplates && allTemplates.length) {
                this.allTemplates = Object.keys(groupBy(allTemplates, (item) => item.templateTag)).filter((item) => item !== 'undefined' && item !== 'null');
                this.templateTagOptions = this.allTemplates;
                this.allGroupingTexts = Object.keys(groupBy(allTemplates, (item) => item.groupingText)).filter((item) => item !== 'undefined' && item !== 'null');
                this.groupingTextsOptions = this.allGroupingTexts;
            }
        });
    }
    subscribeToAutocomplete() {
        this._ngUnsubscribe.add(this.createTemplateForm.controls['templateTag'].valueChanges.subscribe((searchValue) => {
            this.templateTagOptions = this.allTemplates.filter((item) => item.toLowerCase().includes(searchValue.toLowerCase()));
        }));
        this._ngUnsubscribe.add(this.createTemplateForm.controls['groupingText'].valueChanges.subscribe((searchValue) => {
            this.groupingTextsOptions = this.allGroupingTexts.filter((item) => item.toLowerCase().includes(searchValue.toLowerCase()));
        }));
    }
    loadConfig() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (!this.config) {
                const configs = (yield this.configService.find());
                if (!configs || !configs.length) {
                    return null;
                }
                this.config = configs[0];
            }
            if (!this.config.dmsConfig) {
                return;
            }
            this.dmsDocumentTypes = this.config.dmsConfig.dmsDocumentTypes;
            this.dmsAccessLists = this.config.dmsConfig.dmsAccessLists;
            this.dmsFieldMappings = this.config.dmsConfig.dmsFieldMappings;
            this.dmsModules = this.config.dmsConfig.dmsModules;
        });
    }
}
