import { Component } from '@angular/core';

import { AuthService } from '../../services/auth.service';
import { FullscreenService } from '../../services/fullscreen.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  public showLogOutButton: boolean;

  constructor(public fullscreenService: FullscreenService, private authService: AuthService) {
    this.authService.isAuthenticated.subscribe(isLogedIn => {
      this.showLogOutButton = isLogedIn;
    });
  }

  async logout() {
    await this.authService.logout();
    window.location.reload();
  }
}
