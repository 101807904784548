<mat-card class="login-card">
  <!-- <mat-card-header>
    <mat-card-title>Login</mat-card-title>
  </mat-card-header> -->
  <mat-card-content>
    <form class="login-form" (submit)="login()">
      <mat-form-field>
        <input matInput placeholder="Username" [(ngModel)]="username" name="username" required />
      </mat-form-field>
      <mat-form-field>
        <input
          matInput
          placeholder="Password"
          [(ngModel)]="password"
          [type]="showPassword ? 'text' : 'password'"
          name="password"
          required
        />
        <mat-icon
          class="far"
          [ngClass]="showPassword ? 'fa-eye' : 'fa-eye-slash'"
          matSuffix
          (click)="showPassword = !showPassword"
        ></mat-icon>
      </mat-form-field>
      <button type="submit" style="display:none;">hidden submit</button>
    </form>

    <mat-progress-bar mode="indeterminate" [style.display]="showSpinner ? 'block' : 'none'"></mat-progress-bar>
  </mat-card-content>
  <mat-card-actions class="login-button">
    <button mat-raised-button (click)="login()" color="primary" style="width: 100%">
      Login
    </button>
  </mat-card-actions>
</mat-card>
