import { Therapy, TherapyType } from '@pia/pia.shared';
import { TherapyItem } from 'src/app/shared/models/therapy-item.model';
import { TherapyTypeItem } from 'src/app/shared/models/therapy-type-item.model';

export class Therapies {
  static complete: TherapyItem[] = [
    new TherapyItem(Therapy.H_UNBEK, 'Unbekannt', 'N.N.', 'alberta-general', '#3B507A', '#B4BED2', []),
    new TherapyItem(Therapy.H_STOMA, 'Entero-/Urostoma', 'H-STOMA', 'alberta-enterostomy', '#7700FF', '#D2ACFF', [
      new TherapyTypeItem(TherapyType.Colostoma_01, '01 Colostoma'),
      new TherapyTypeItem(TherapyType.Ileostoma_01, '01 Ileostoma'),
      new TherapyTypeItem(TherapyType.Urostoma_01, '01 Urostoma'),
    ]),
    new TherapyItem(Therapy.H_INKO, 'Harn-Inkontinenz', 'H-INKO', 'alberta-incontinence', '#00DFCB', '#C0FAF4', [
      new TherapyTypeItem(TherapyType.Aufsaugende_Versorgung_02, '02 aufsaugende Versorgung'),
      new TherapyTypeItem(TherapyType.Perkutane_Nephrostomie_02, '02 Perkutane Nephrostomie'),
      new TherapyTypeItem(
        TherapyType.Cystostomie_Punktionsfistel_Suprapubisch_02,
        '02 Cystostomie/Punktionsfistel, suprapubisch'
      ),
      new TherapyTypeItem(TherapyType.Transurethrale_Ableitung_02, '02 Transurethrale Ableitung'),
      new TherapyTypeItem(
        TherapyType.Ableitung_Pouch_katheterisierbar_02,
        '02 Ableitung über Pouch, katheterisierbar (MAINZ-Pouch, Mitrofanoff-Stoma)'
      ),
      new TherapyTypeItem(
        TherapyType.Intermitierender_Selbstkatheterismus_02,
        '02 Intermitierender Selbstkatheterismus, auch IFK'
      ),
      new TherapyTypeItem(TherapyType.Externe_Ableitung_02, '02 Externe Ableitung, Kondom'),
      new TherapyTypeItem(TherapyType.Therapie_sonstige_02, '02 Therapie, sonstige (Vaginaltampon etc.)'),
      new TherapyTypeItem(
        TherapyType.Externe_Ableitung_Urinalsystem_Bandage_02,
        '02 Externe Ableitung, Urinalsystem/-bandage'
      ),
    ]),
    new TherapyItem(Therapy.H_S_INKO, 'Stuhl-Inkontinenz ', 'H-S-INKO', 'alberta-incontinence', '#00DFCB', '#C0FAF4', [
      new TherapyTypeItem(TherapyType.Transanale_Irrigation_02, '02 Transanale Irrigation'),
      new TherapyTypeItem(TherapyType.Auffang_Faekalkollektor_02, '02 Auffang mit Fäkalkollektor'),
      new TherapyTypeItem(TherapyType.Rueckhalt_Analtampon_02, '02 Rückhalt mit Analtampon'),
    ]),
    new TherapyItem(Therapy.H_WUND, 'Wunde, chronisch', 'H-WUND', 'alberta-wound', '#AE00A2', '#E1A6DF', [
      new TherapyTypeItem(TherapyType.Wunde_03, '03 Wunde'),
    ]),
    new TherapyItem(Therapy.H_EE, 'Enterale Ernährung ', 'H-EE', 'alberta-enteral-nutrition', '#FFD014', '#FFEEAE', [
      new TherapyTypeItem(TherapyType.Schwerkraftversorgung_04, '04 Schwerkraftversorgung'),
      new TherapyTypeItem(TherapyType.Pumpenversorgung_04, '04 Pumpenversorgung'),
      new TherapyTypeItem(
        TherapyType.Versorgung_Bolusgabe_Button_Gastrotube,
        '04 Versorgung mittels Bolusgabe/Button-Gastrotube'
      ),
      new TherapyTypeItem(TherapyType.Trinknahrung_04, '04 Trinknahrung'),
      new TherapyTypeItem(TherapyType.Absaugung_04, '04 Absaugung'),
    ]),
    new TherapyItem(
      Therapy.H_PE,
      'Pareneterale Ernährung ',
      'H-PE',
      'alberta-enteral-nutrition',
      '#FFD014',
      '#FFEEAE',
      [
        new TherapyTypeItem(TherapyType.Schwerkraftversorgung_05, '05 Schwerkraftversorgung'),
        new TherapyTypeItem(TherapyType.Pumpenversorgung_05, '05 Pumpenversorgung'),
      ]
    ),
    new TherapyItem(Therapy.H_TRACHERO, 'Tracheostoma', 'H-TRACHEO', 'alberta-tracheostomy', '#0080FF', '#A6D2FF', [
      new TherapyTypeItem(TherapyType.Tracheostoma_beatmet_06, '06 Tracheostoma, beatmet'),
      new TherapyTypeItem(TherapyType.Tracheostoma_unbeatmet_06, '06 Tracheostoma, unbeatmet'),
      new TherapyTypeItem(TherapyType.Larynektomie_06, '06 Larynektomie '),
      new TherapyTypeItem(TherapyType.Absaugung_06, '06 Absaugung'),
    ]),
    new TherapyItem(
      Therapy.H_SCHMERZ,
      'Schmerz- & Antibiose-Therapie',
      'H-SCHMERZ',
      'alberta-pain-therapy',
      '#9E0059',
      '#E7BFD5',
      [
        new TherapyTypeItem(TherapyType.Schmerztherapie_08, '08 Schmerztherapie'),
        new TherapyTypeItem(TherapyType.Antibiose_Therapie_08, '08 Antibiose-Therapie'),
      ]
    ),
    new TherapyItem(Therapy.H_FISTEL, 'Wundfistel', 'H-FISTEL', 'alberta-wound', '#AE00A2', '#E1A6DF', [
      new TherapyTypeItem(TherapyType.Wundfistel_09, '09 Wundfistel'),
    ]),
    new TherapyItem(
      Therapy.H_SONDER,
      'Spezialversorgung/Sonderform',
      'REHA',
      'alberta-rehab-aids',
      '#AE00A2',
      '#F2BDF0',
      [        
        new TherapyTypeItem(TherapyType.Freiverkauf_Reiner_99, '99 Freiverkauf, reiner'),
        new TherapyTypeItem(TherapyType.Werbemittel_99, '99 Werbemittel'),
        new TherapyTypeItem(TherapyType.Umlagerung_Autolager_AD_99, '99 Umlagerung Autolager AD'),
        new TherapyTypeItem(TherapyType.Muster_99, '99 Muster'),
        new TherapyTypeItem(TherapyType.Eigenbedarf_99, '99 Eigenbedarf'),
        new TherapyTypeItem(TherapyType.Grosshandel_94, '94 Großhandel'),
        new TherapyTypeItem(TherapyType.Pharm_Grosshandel_05, '05 pharm. Großhandel'),
        new TherapyTypeItem(TherapyType.Sonstige_Anwendung_95, '95 Sonstige Anwendung'),
      ]
    ),
    //   new TherapyItem(Therapy.NN, 'Unbekannt', 'N.N.', 'alberta-general', '#3B507A', '#B4BED2', []),
    //   new TherapyItem(Therapy.PE, 'Parenterale Ernährung', 'PE',
    // 'alberta-parenteral-feeding', '#00CF00', '#BEF2BE', [
    //     new TherapyTypeItem(TherapyType.Schwerkraft_PE, 'Schwerkraft'),
    //     new TherapyTypeItem(TherapyType.Pumpe_immobil_PE, 'Pumpe immobil'),
    //     new TherapyTypeItem(TherapyType.Pumpe_mobil_PE, 'Pumpe mobil'),
    //     new TherapyTypeItem(TherapyType.Compounding, 'Compounding'),
    //     new TherapyTypeItem(TherapyType.PE_Kinder, 'PE Kinder'),
    //   ]),
    //   new TherapyItem(Therapy.EE, 'Enterale Ernährung', 'EE', 'alberta-enteral-nutrition', '#FFD014', '#FFEEAE', [
    //     new TherapyTypeItem(TherapyType.Schwerkraft_EE, 'Schwerkraft'),
    //     new TherapyTypeItem(TherapyType.Pumpe_immobil_EE, 'Pumpe immobil'),
    //     new TherapyTypeItem(TherapyType.Pumpe_mobil_EE, 'Pumpe mobil'),
    //     new TherapyTypeItem(TherapyType.Bolus, 'Bolus'),
    //     new TherapyTypeItem(TherapyType.Trinknahrung, 'Trinknahrung'),
    //     new TherapyTypeItem(TherapyType.EE_Kinder, 'EE Kinder'),
    //     new TherapyTypeItem(TherapyType.EE_Technik, 'EE Technik'),
    //   ]),
    //   new TherapyItem(Therapy.TS, 'Tracheostoma', 'TS', 'alberta-tracheostomy', '#0080FF', '#A6D2FF', [
    //     new TherapyTypeItem(TherapyType.Tracheostoma_beatmet, 'Tracheostoma beatmet'),
    //     new TherapyTypeItem(TherapyType.Laryngektomie_beatmet, 'Laryngektomie beatmet'),
    //     new TherapyTypeItem(TherapyType.Tracheostoma_unbeatmet, 'Tracheostoma unbeatmet'),
    //     new TherapyTypeItem(TherapyType.Laryngektomie_unbeatmet, 'Laryngektomie unbeatmet'),
    //     new TherapyTypeItem(TherapyType.Absaugung_außerhalb_von_TS, 'Absaugung außerhalb von TS'),
    //     new TherapyTypeItem(TherapyType.Wachkoma, 'Wachkoma'),
    //     new TherapyTypeItem(TherapyType.Laryngektomie, 'Laryngektomie'),
    //     new TherapyTypeItem(TherapyType.Inhalation_obere_Atemwege, 'Inhalation obere Atemwege'),
    //     new TherapyTypeItem(TherapyType.Inhalation_unterer_Atemwege, 'Inhalation unterer Atemwege'),
    //     new TherapyTypeItem(TherapyType.Absaugung_endotracheal, 'Absaugung endotracheal'),
    //     new TherapyTypeItem(TherapyType.Absaugung_oral, 'Absaugung oral'),
    //     new TherapyTypeItem(TherapyType.Tracheostoma, 'Tracheostoma'),
    //     new TherapyTypeItem(TherapyType.Absaugung, 'Absaugung'),
    //   ]),
    //   new TherapyItem(Therapy.WV, 'Wundversorgung', 'WV', 'alberta-wound', '#AE00A2', '#E1A6DF', [
    //     new TherapyTypeItem(TherapyType.Ulcus_cruris, 'Ulcus cruris'),
    //     new TherapyTypeItem(TherapyType.Dekubitus, 'Dekubitus'),
    //     new TherapyTypeItem(TherapyType.Diabetisches_Fußsyndrom, 'Diabetisches Fußsyndrom'),
    //     new TherapyTypeItem(TherapyType.Ulzerierte_Tumore, 'Ulzerierte Tumore'),
    //     new TherapyTypeItem(TherapyType.Wundfisteln, 'Wundfisteln'),
    //     new TherapyTypeItem(TherapyType.sonstige_chronische_Wunden, 'Sonstige chronische Wunden'),
    //     new TherapyTypeItem(TherapyType.sonstige_Wunden, 'Sonstige Wunden'),
    //     new TherapyTypeItem(TherapyType.Akute_Wunde, 'Akute Wunde'),
    //   ]),
    //   new TherapyItem(Therapy.IV, 'IV-Therapien', 'IV', 'alberta-parenteral-feeding', '#00CF00', '#BEF2BE', [
    //     new TherapyTypeItem(TherapyType.Compounding, 'Compounding'),
    //     new TherapyTypeItem(TherapyType.Antibiose, 'Antibiose'),
    //     new TherapyTypeItem(TherapyType.Schmerztherapie, 'Schmerztherapie'),
    //     new TherapyTypeItem(TherapyType.Medikamententherapie, 'Medikamententherapie'),
    //     new TherapyTypeItem(TherapyType.Immunglobulintherapie, 'Immunglobulintherapie'),
    //     new TherapyTypeItem(TherapyType.Schmerztherapie_Pumpe_mobil, 'Schmerztherapie (Pumpe mobil)'),
    //   ]),
    //   new TherapyItem(Therapy.BEA, 'Beatmung', 'BEA', 'alberta-tracheostomy', '#0080FF', '#A6D2FF', [
    //     new TherapyTypeItem(TherapyType.Beatmung_invasiv, 'Invasiv'),
    //     new TherapyTypeItem(TherapyType.Beatmung_nicht_invasiv, 'Nicht Invasiv'),
    //     new TherapyTypeItem(TherapyType.Befeuchtung_aktiv, 'Befeuchtung Aktiv'),
    //     new TherapyTypeItem(TherapyType.Befeuchtung_passiv, 'Befeuchtung Passiv'),
    //     new TherapyTypeItem(TherapyType.Befeuchtung_integriert, 'Befeuchtung Integriert'),
    //     new TherapyTypeItem(TherapyType.High_Flow, 'High Flow'),
    //     new TherapyTypeItem(TherapyType.Beatmung_IV, 'Beatmung IV'),
    //     new TherapyTypeItem(TherapyType.Beatmung_NIV, 'Beatmung NIV'),
    //   ]),
    //   new TherapyItem(Therapy.SON, 'Sonstiges', 'SON', 'alberta-therapy-miscellaneous', '#f84b12', '#fdc8b7', [
    //     new TherapyTypeItem(TherapyType.Diabetes, 'Diabetes'),
    //     new TherapyTypeItem(TherapyType.Pflegehilfsmittel, 'Pflegehilfsmittel'),
    //     new TherapyTypeItem(TherapyType.Fistel, 'Fistel'),
    //     new TherapyTypeItem(TherapyType.Haemophilie, 'Hämophilie'),
    //     new TherapyTypeItem(TherapyType.Lungenhochdruck_PAH, 'Lungenhochdruck (PAH)'),
    //   ]),
    //   new TherapyItem(Therapy.OSA, 'OSA', 'OSA', 'alberta-medical-technology', '#FF0045', '#FFCCDA', [
    //     new TherapyTypeItem(TherapyType.CPAP, 'CPAP'),
    //     new TherapyTypeItem(TherapyType.APAP, 'APAP'),
    //     new TherapyTypeItem(TherapyType.Bilevel, 'Bilevel'),
    //     new TherapyTypeItem(TherapyType.Bilevel_ST, 'Bilevel-ST'),
    //     new TherapyTypeItem(TherapyType.Auto_Bilevel, 'Auto Bilevel'),
    //     new TherapyTypeItem(TherapyType.CS, 'CS'),
    //     new TherapyTypeItem(TherapyType.ASV, 'ASV'),
    //     new TherapyTypeItem(TherapyType.Bilevel_Auto, 'Bilevel Auto'),
    //     new TherapyTypeItem(TherapyType.Bilevel_S, 'Bilevel S'),
    //     new TherapyTypeItem(TherapyType.AirMini, 'AirMini'),
    //   ]),
    //   new TherapyItem(Therapy.HH, 'Hustenhilfen', 'HH', 'alberta-medical-technology', '#6352E9', '#E0DCFB', [
    //     new TherapyTypeItem(TherapyType.Hustenhilfen, 'Hustenhilfen'),
    //     new TherapyTypeItem(TherapyType.Sekretmobilisation, 'Sekretmobilisation'),
    //   ]),
    //   new TherapyItem(Therapy.ABS, 'Absaugung', 'ABS', 'alberta-medical-technology', '#3123AF', '#D5D3EF', [
    //     new TherapyTypeItem(TherapyType.Absaugung_oral, 'Oral'),
    //     new TherapyTypeItem(TherapyType.Absaugung_subglottisch, 'Subglottisch'),
    //   ]),
    //   new TherapyItem(Therapy.PUE, 'Patientenüberwachung', 'PUE',
    // 'alberta-medical-technology', '#235EE8', '#D3DFFB', [
    //     new TherapyTypeItem(TherapyType.Monitoring, 'Monitoring'),
    //     new TherapyTypeItem(TherapyType.Pulsoximetrie, 'Pulsoximetrie'),
    //     new TherapyTypeItem(TherapyType.Fingerpulsoximetrie, 'Fingerpulsoximetrie'),
    //     new TherapyTypeItem(TherapyType.Kapnometer, 'Kapnometer'),
    //     new TherapyTypeItem(TherapyType.Blutdruckmessgeraet, 'Blutdruckmessgerät'),
    //   ]),
    //   new TherapyItem(Therapy.SS, 'Sauerstoff', 'SS', 'alberta-medical-technology', '#00B1EC', '#BCEEFF', [
    //     new TherapyTypeItem(TherapyType.Sauerstoff_mobil, 'Mobil'),
    //     new TherapyTypeItem(TherapyType.Sauerstoff_fluessig, 'Flüssig'),
    //     new TherapyTypeItem(TherapyType.Sauerstoff_stationaer, 'Stationär'),
    //     new TherapyTypeItem(TherapyType.GOX, 'GOX'),
    //     new TherapyTypeItem(TherapyType.Konzentrator_mobil, 'Konzentrator Mobil'),
    //     new TherapyTypeItem(TherapyType.Konzentrator_stationaer, 'Konzentrator Stationär'),
    //     new TherapyTypeItem(TherapyType.LOX, 'LOX'),
    //     new TherapyTypeItem(TherapyType.O2_Flaschenversorgung, 'O² Flaschenversorgung'),
    //     new TherapyTypeItem(TherapyType.Sparsystem_mit_O2_Flasche, 'Sparsystem mit O² Flasche'),
    //   ]),
    //   new TherapyItem(
    //     Therapy.IUA,
    //     'Inhalations- und Atemtherapie',
    //     'IUA',
    //     'alberta-medical-technology',
    //     '#00B580',
    //     '#CCF0E6',
    //     [
    //       new TherapyTypeItem(TherapyType.Inhalation, 'Inhalation'),
    //       new TherapyTypeItem(TherapyType.Medikamentenvernebler, 'Medikamentenvernebler'),
    //       new TherapyTypeItem(TherapyType.Ultraschallvernebler, 'Ultraschallvernebler'),
    //       new TherapyTypeItem(TherapyType.PEP_Systeme, 'PEP-Systeme'),
    //       new TherapyTypeItem(TherapyType.IPPB_Inhalation, 'IPPB-Inhalation'),
    //       new TherapyTypeItem(TherapyType.Atemtherapie, 'Atemtherapie'),
    //     ]
    //   ),
    //   new TherapyItem(Therapy.LHM, 'Lagerungshilfsmittel', 'LHM', 'alberta-storage-aids', '#011770', '#B2B9D4', [
    //     new TherapyTypeItem(TherapyType.Anti_Dekubitussystem, 'Anti-Dekubitussysteme'),
    //   ]),
    //   new TherapyItem(Therapy.ST, 'Schmerztherapie', 'ST', 'alberta-pain-therapy', '#9E0059', '#E7BFD5', [
    //     new TherapyTypeItem(TherapyType.Pumpe_mobil_ST, 'Pumpe mobil'),
    //   ]),
    //   new TherapyItem(Therapy.IT, 'Immuntherapie', 'IT', 'alberta-immunotherapy', '#E328DD', '#F8C9F7', [
    //     new TherapyTypeItem(TherapyType.Movy, 'Movy'),
    //     new TherapyTypeItem(TherapyType.Hizentra, 'Hizentra'),
    //   ]),
    //   new TherapyItem(Therapy.RH, 'Rehydration', 'RH', 'alberta-rehydration', '#5785EF', '#CCDAFA', [
    //     new TherapyTypeItem(TherapyType.Schwerkraft_RH, 'Schwerkraft'),
    //     new TherapyTypeItem(TherapyType.Pumpe_mobil_RH, 'Pumpe mobil'),
    //   ]),
    //   new TherapyItem(Therapy.Cross, 'Therapieübergreifend',
    // 'T.Ü.', 'alberta-cross-therapy', '#3B507A', '#CEDAF2', []),
    //   new TherapyItem(Therapy.BEF, 'Befeuchtung', 'BEF', 'alberta-humidification', '#5785EF', '#CCDAFA', [
    //     new TherapyTypeItem(TherapyType.Befeuchtung, 'Befeuchtung'),
    //   ]),
    //   new TherapyItem(Therapy.HF, 'High-Flow', 'HF', 'alberta-high-flow', '#00B1EC', '#BCEEFF', [
    //     new TherapyTypeItem(TherapyType.High_Flow_HF, 'High-Flow'),
    //   ]),
    //   new TherapyItem(Therapy.AT, 'Atemtherapie', 'AT', 'alberta-breath-therapy', '#00B5A3', '#C2F2ED', [
    //     new TherapyTypeItem(TherapyType.Hustenassistent, 'Hustenassistent'),
    //     new TherapyTypeItem(TherapyType.Inhalation_AT, 'Inhalation'),
    //   ]),
    //   new TherapyItem(Therapy.MON, 'Monitoring', 'MON', 'alberta-monitoring', '', '', [
    //     new TherapyTypeItem(TherapyType.Kapnographie, 'Kapnographie'),
    //     new TherapyTypeItem(TherapyType.Herz_Atem_Monitor, 'Herz-Atem-Monitor'),
    //     new TherapyTypeItem(TherapyType.Pulsoximeter, 'Pulsoximeter'),
    //   ]),
    //   new TherapyItem(Therapy.DIA, 'Diagnostik', 'DIA', 'alberta-diagnostics', '#9EB500', '#EEF3CA', [
    //     new TherapyTypeItem(TherapyType.Schlafdiagnostik, 'Schlafdiagnostik'),
    //   ]),
    //   new TherapyItem(Therapy.ZS, 'Zahnschiene', 'ZS', 'alberta-tooth-splint', '#FF0045', '#FFCCDA', [
    //     new TherapyTypeItem(TherapyType.Zahnschiene, 'Zahnschiene'),
    //   ]),
    //   new TherapyItem(Therapy.SSB, 'Sitzschalenbau', 'SSB',
    // 'alberta-seat-shell-construction', '#011770', '#C7CFED', [
    //     new TherapyTypeItem(TherapyType.ausgelagerte_Fertigung, 'ausgelagerte Fertigung'),
    //     new TherapyTypeItem(TherapyType.Selbstherstellung, 'Selbstherstellung'),
    //   ]),
    //   new TherapyItem(
    //     Therapy.ORTHO,
    //     'Orthopädietechnik',
    //     'ORTHO',
    //     'alberta-orthopedic-technology',
    //     '#00B580',
    //     '#C7EFE3',
    //     [
    //       new TherapyTypeItem(TherapyType.Schienen, 'Schienen'),
    //       new TherapyTypeItem(TherapyType.Kompressionsstruempfe, 'Kompressionsstrümpfe'),
    //       new TherapyTypeItem(TherapyType.Bandagen, 'Bandagen'),
    //       new TherapyTypeItem(TherapyType.Oberschenkel_Prothese, 'Oberschenkel Prothese'),
    //       new TherapyTypeItem(TherapyType.Unterschenkel_Prothese, 'Unterschenkel Prothese'),
    //       new TherapyTypeItem(TherapyType.Sonstiges_ORTHO, 'Sonstiges'),
    //     ]
    //   ),
    //   new TherapyItem(Therapy.REHA, 'Reha Hilfsmittel', 'REHA', 'alberta-rehab-aids', '#AE00A2', '#F2BDF0', [
    //     new TherapyTypeItem(TherapyType.Standard_REHA, 'Standard'),
    //     new TherapyTypeItem(TherapyType.Schwerlast, 'Schwerlast'),
    //     new TherapyTypeItem(TherapyType.Anti_Dekubitus_REHA, 'Anti Dekubitus'),
    //     new TherapyTypeItem(TherapyType.Rollstuhl_elektrisch, 'Rollstuhl elektrisch'),
    //     new TherapyTypeItem(TherapyType.Sonderbau_REHA, 'Sonderbau'),
    //   ]),
    //   new TherapyItem(Therapy.ESTI, 'Elektrostimulation',
    // 'ESTI', 'alberta-electrostimulation', '#9E0059', '#EFD4E3', [
    //     new TherapyTypeItem(TherapyType.TENS, 'TENS'),
    //     new TherapyTypeItem(TherapyType.EMS, 'EMS'),
    //     new TherapyTypeItem(TherapyType.TENS_EMS, 'TENS/EMS'),
    //   ]),
  ];
}
