import * as tslib_1 from "tslib";
import { EventEmitter, OnInit } from '@angular/core';
import { take } from 'rxjs/operators';
import { CopyTemplateDialogComponent } from '../copy-template-dialog/copy-template-dialog.component';
import { CreateEditTemplateDialogComponent } from '../create-edit-template-dialog/create-edit-template-dialog.component';
export class TemplateMoreMenuComponent {
    constructor(_dialog, _templatService, _deleteDialog, _snackBar) {
        this._dialog = _dialog;
        this._templatService = _templatService;
        this._deleteDialog = _deleteDialog;
        this._snackBar = _snackBar;
        this.update = new EventEmitter();
        this.archivedToggled = new EventEmitter();
    }
    ngOnInit() { }
    openMenu(event) {
        event.stopPropagation();
    }
    duplicate() {
        this._dialog
            .open(CopyTemplateDialogComponent, {
            width: '400px',
            maxHeight: '800px',
            data: { template: Object.assign({}, this.template) },
        })
            .afterClosed()
            .subscribe(result => {
            if (result) {
                this.update.emit(result._id);
            }
        });
    }
    edit() {
        this._dialog
            .open(CreateEditTemplateDialogComponent, {
            width: '400px',
            maxHeight: '800px',
            data: { template: Object.assign({}, this.template) },
        })
            .afterClosed()
            .subscribe(result => {
            if (result) {
                this.update.emit(result._id);
            }
        });
    }
    toggleDraft() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const draft = !this.template.draft;
            yield this._templatService.patch(this.template._id, { draft });
            this.template.draft = draft;
        });
    }
    toggleArchived() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const displayErrorToast = (message) => {
                this._snackBar.open(message, undefined, {
                    duration: 3000,
                });
            };
            return new Promise((resolve) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                if (!this.template.archived) {
                    this._deleteDialog.show('die Vorlage', this.template.title, false);
                    this._deleteDialog.afterDelConfirm.pipe(take(1)).subscribe(() => tslib_1.__awaiter(this, void 0, void 0, function* () {
                        const deletedTemplate = yield this._templatService
                            .patch(this.template._id, { archived: true })
                            .catch(displayErrorToast);
                        if (deletedTemplate) {
                            this.archivedToggled.emit(true);
                        }
                    }));
                }
                else {
                    const deletedTemplate = yield this._templatService
                        .patch(this.template._id, { archived: false, draft: true })
                        .catch(displayErrorToast);
                    if (deletedTemplate) {
                        this.archivedToggled.emit(false);
                    }
                }
            }));
        });
    }
}
