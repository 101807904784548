/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./template-report.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../shared/layout/spinner/spinner.component.ngfactory";
import * as i3 from "../../../shared/layout/spinner/spinner.component";
import * as i4 from "@angular/common";
import * as i5 from "./template-report.component";
import * as i6 from "../../../shared/services/report.service";
import * as i7 from "../../../shared/services/create-audit.service";
import * as i8 from "../../../shared/services/audit-report.service";
var styles_TemplateReportComponent = [i0.styles];
var RenderType_TemplateReportComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TemplateReportComponent, data: {} });
export { RenderType_TemplateReportComponent as RenderType_TemplateReportComponent };
function View_TemplateReportComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-spinner", [], null, null, null, i2.View_SpinnerComponent_0, i2.RenderType_SpinnerComponent)), i1.ɵdid(1, 49152, null, 0, i3.SpinnerComponent, [], null, null)], null, null); }
export function View_TemplateReportComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { _designer: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["style", "overflow: hidden"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TemplateReportComponent_1)), i1.ɵdid(3, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(4, 0, [[1, 0], ["designer", 1]], null, 0, "div", [], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.showSpinner; _ck(_v, 3, 0, currVal_0); }, null); }
export function View_TemplateReportComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-template-report", [], null, null, null, View_TemplateReportComponent_0, RenderType_TemplateReportComponent)), i1.ɵdid(1, 638976, null, 0, i5.TemplateReportComponent, [i6.ReportService, i7.CreateAuditService, i8.AuditReportService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TemplateReportComponentNgFactory = i1.ɵccf("app-template-report", i5.TemplateReportComponent, View_TemplateReportComponent_Host_0, { template: "template" }, {}, []);
export { TemplateReportComponentNgFactory as TemplateReportComponentNgFactory };
