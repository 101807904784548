import { AlbertaMatchField } from 'src/app/shared/models/alberta-match-field.model';

export const AlbertaPatientsMatchFields: AlbertaMatchField[] = [
  {
    _id: 1,
    path: 'firstName',
    title: 'Patient - Vorname',
  },
  {
    _id: 2,
    path: 'lastName',
    title: 'Patient - Nachname',
  },
  {
    _id: 3,
    path: 'birthday',
    title: 'Patient - Geburtsdatum',
  },
  {
    _id: 4,
    path: 'customerId',
    title: 'Patient - KundenNr',
  },
  {
    _id: 5,
    path: 'address',
    title: 'Patient - Strasse HausNr.',
  },
  {
    _id: 6,
    modelName: 'postalCode',
    path: 'postalCodeId',
    modelPath: 'postalCode',
    title: 'Patient - PLZ',
  },
  {
    _id: 7,
    modelName: 'postalCode',
    path: 'postalCodeId',
    modelPath: 'city',
    title: 'Patient - Ort',
  },
  {
    _id: 8,
    path: 'payer.numberOfInsured',
    title: 'Patient - Versichertennummer',
  },
  {
    _id: 9,
    modelName: 'payer',
    path: 'payer.payerId',
    modelPath: 'name',
    title: 'Patient - Krankenkasse',
  },
  {
    _id: 10,
    path: 'gender',
    title: 'Patient - Geschlecht',
    lookup: {
      0: 'Unbekannt',
      1: 'Weiblich',
      2: 'Männlich',
      3: 'Sonstige',
    },
  },

  {
    _id: 11,
    path: 'phone',
    title: 'Patient - Telefon',
  },
  {
    _id: 12,
    path: 'mobilePhone',
    title: 'Patient - Mobile',
  },
  {
    _id: 13,
    path: 'email',
    title: 'Patient - E-Mail',
  },
  {
    _id: 14,
    path: 'payer.payerType',
    title: 'Patient - priv/ges Versicherung',
    lookup: {
      0: 'unbekannt',
      1: 'gesetzlich',
      2: 'privat',
      3: 'Berufsgenossenschaft',
      4: 'Selbstzahler',
    },
  },
  {
    _id: 15,
    path: 'payer.copaymentToDate',
    title: 'Patient - Zuzahlungsbefreit bis',
  },
  {
    _id: 16,
    path: 'gender',
    title: 'Patient - Anrede',
    lookup: {
      0: '',
      1: 'Frau',
      2: 'Herr',
      3: '',
    },
  },
  {
    _id: 17,
    entity: 'audit',
    path: 'woundLocation',
    title: 'Dokument - Wundlokalisation',
  },
  {
    _id: 18,
    entity: 'patient',
    modelName: 'nursingService',
    path: 'nursingServiceId',
    modelPath: 'name',
    title: 'Pflegedienst - Name',
  },
  {
    _id: 19,
    entity: 'patient',
    modelName: 'nursingService',
    path: 'nursingServiceId',
    modelPath: 'address',
    title: 'Pflegedienst - Strasse',
  },
  {
    _id: 20,
    entity: 'patient',
    modelName: 'nursingService',
    path: 'nursingServiceId',
    modelPath: 'postalCode',
    title: 'Pflegedienst - PLZ',
  },
  {
    _id: 21,
    entity: 'patient',
    modelName: 'nursingService',
    path: 'nursingServiceId',
    modelPath: 'city',
    title: 'Pflegedienst - Ort',
  },
  {
    _id: 22,
    entity: 'patient',
    modelName: 'nursingHome',
    path: 'nursingHomeId',
    modelPath: 'name',
    title: 'Pflegeheim - Name',
  },
  {
    _id: 23,
    entity: 'patient',
    modelName: 'nursingHome',
    path: 'nursingHomeId',
    modelPath: 'address',
    title: 'Pflegeheim - Strasse',
  },
  {
    _id: 24,
    entity: 'patient',
    modelName: 'nursingHome',
    path: 'nursingHomeId',
    modelPath: 'postalCode',
    title: 'Pflegeheim - PLZ',
  },
  {
    _id: 25,
    entity: 'patient',
    modelName: 'nursingHome',
    path: 'nursingHomeId',
    modelPath: 'city',
    title: 'Pflegeheim - Ort',
  },
  {
    _id: 26,
    entity: 'patient',
    modelName: 'doctor',
    path: 'primaryDoctorId',
    modelPath: 'title',
    title: 'Arzt - Titel',
  },
  {
    _id: 27,
    entity: 'patient',
    modelName: 'doctor',
    path: 'primaryDoctorId',
    modelPath: 'firstName',
    title: 'Arzt - Vorname',
  },
  {
    _id: 28,
    entity: 'patient',
    modelName: 'doctor',
    path: 'primaryDoctorId',
    modelPath: 'lastName',
    title: 'Arzt - Nachname',
  },
  {
    _id: 29,
    entity: 'patient',
    modelName: 'doctor',
    path: 'primaryDoctorId',
    modelPath: 'address',
    title: 'Arzt - Strasse',
  },
  {
    _id: 30,
    entity: 'patient',
    modelName: 'doctor',
    path: 'primaryDoctorId',
    modelPath: 'postalCode',
    title: 'Arzt - PLZ',
  },
  {
    _id: 31,
    entity: 'patient',
    modelName: 'doctor',
    path: 'primaryDoctorId',
    modelPath: 'city',
    title: 'Arzt - Ort',
  },
  {
    _id: 32,
    entity: 'audit',
    path: 'therapy',
    title: 'Dokument - Therapie',
  },
  {
    _id: 33,
    entity: 'audit',
    path: 'therapyType',
    title: 'Dokument - Therapietyp',
  },
  {
    _id: 34,
    entity: 'patient',
    modelName: 'pharmacy',
    path: 'pharmacyId',
    modelPath: 'name',
    title: 'Apotheke - Name',
  },
  {
    _id: 35,
    entity: 'patient',
    modelName: 'pharmacy',
    path: 'pharmacyId',
    modelPath: 'address',
    title: 'Apotheke - Strasse',
  },
  {
    _id: 36,
    entity: 'patient',
    modelName: 'pharmacy',
    path: 'pharmacyId',
    modelPath: 'postalCode',
    title: 'Apotheke - PLZ',
  },
  {
    _id: 37,
    entity: 'patient',
    modelName: 'pharmacy',
    path: 'pharmacyId',
    modelPath: 'city',
    title: 'Apotheke - Ort',
  },
  {
    _id: 38,
    entity: 'user',
    path: 'firstName',
    title: 'Benutzer - Vorname',
  },
  {
    _id: 39,
    entity: 'user',
    path: 'lastName',
    title: 'Benutzer - Nachname',
  },
  {
    _id: 40,
    entity: 'user',
    path: 'address',
    title: 'Benutzer - Strasse',
  },
  {
    _id: 41,
    entity: 'user',
    path: 'postalCodeId',
    title: 'Benutzer - PLZ',
  },
  {
    _id: 42,
    entity: 'user',
    path: 'phone',
    title: 'Benutzer - Telefon',
  },
  {
    _id: 43,
    entity: 'user',
    path: 'mobilePhone',
    title: 'Benutzer - Mobil',
  },
  {
    _id: 44,
    entity: 'user',
    path: 'fax',
    title: 'Benutzer - Fax',
  },
  {
    _id: 45,
    entity: 'user',
    path: 'email',
    title: 'Benutzer - EMail',
  },
  {
    _id: 46,
    entity: 'patient',
    modelName: 'doctor',
    path: 'primaryDoctorId',
    modelPath: 'phone',
    title: 'Arzt - Telefon',
  },
  {
    _id: 47,
    entity: 'patient',
    modelName: 'doctor',
    path: 'primaryDoctorId',
    modelPath: 'fax',
    title: 'Arzt - Fax',
  },
  {
    _id: 48,
    entity: 'patient',
    modelName: 'pharmacy',
    path: 'pharmacyId',
    modelPath: 'phone',
    title: 'Apotheke - Telefon',
  },
  {
    _id: 49,
    entity: 'patient',
    modelName: 'pharmacy',
    path: 'pharmacyId',
    modelPath: 'fax',
    title: 'Apotheke - Fax',
  },
  {
    _id: 50,
    entity: 'patient',
    modelName: 'nursingHome',
    path: 'nursingHomeId',
    modelPath: 'phone',
    title: 'Pflegeheim - Telefon',
  },
  {
    _id: 51,
    entity: 'patient',
    modelName: 'nursingHome',
    path: 'nursingHomeId',
    modelPath: 'fax',
    title: 'Pflegeheim - Fax',
  },
  {
    _id: 52,
    entity: 'patient',
    modelName: 'nursingService',
    path: 'nursingServiceId',
    modelPath: 'phone',
    title: 'Pflegedienst - Telefon',
  },
  {
    _id: 53,
    entity: 'patient',
    modelName: 'nursingService',
    path: 'nursingServiceId',
    modelPath: 'fax',
    title: 'Pflegedienst - Fax',
  },
  {
    _id: 54,
    entity: 'patient',
    modelName: 'hospital',
    path: 'hospital.hospitalId',
    modelPath: 'phone',
    title: 'Klinik - Telefon',
  },
  {
    _id: 55,
    entity: 'patient',
    modelName: 'hospital',
    path: 'hospital.hospitalId',
    modelPath: 'fax',
    title: 'Klinik - Fax',
  },
];

export const AlbertaInstitutionsMatchFields: AlbertaMatchField[] = [
  {
    _id: 56,
    path: 'name',
    title: 'Institution - Name',
  },
  {
    _id: 57,
    path: 'address',
    title: 'Institution - Adresse',
  },
  {
    _id: 58,
    path: 'postalCode',
    title: 'Institution - Postleitzahl',
  },
  {
    _id: 59,
    path: 'city',
    title: 'Institution - Stadt',
  },
  {
    _id: 60,
    path: 'phone',
    title: 'Institution - Telefon',
  },
  {
    _id: 61,
    path: 'fax',
    title: 'Institution - Fax',
  },
  {
    _id: 62,
    path: 'email',
    title: 'Institution - E-Mail',
  },
  {
    _id: 63,
    entity: 'user',
    path: 'firstName',
    title: 'Benutzer - Vorname',
  },
  {
    _id: 64,
    entity: 'user',
    path: 'lastName',
    title: 'Benutzer - Nachname',
  },
  {
    _id: 67,
    entity: 'user',
    path: 'phone',
    title: 'Benutzer - Telefon',
  },
  {
    _id: 68,
    entity: 'user',
    path: 'mobilePhone',
    title: 'Benutzer - Mobil',
  },
  {
    _id: 69,
    entity: 'user',
    path: 'fax',
    title: 'Benutzer - Fax',
  },
  {
    _id: 70,
    entity: 'user',
    path: 'email',
    title: 'Benutzer - EMail',
  },
];
