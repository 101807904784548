/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./table.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "@angular/material/table";
import * as i4 from "@angular/cdk/table";
import * as i5 from "../../../../../node_modules/@angular/material/table/typings/index.ngfactory";
import * as i6 from "@angular/cdk/bidi";
import * as i7 from "@angular/cdk/platform";
import * as i8 from "../../pipes/upper-first.pipe";
import * as i9 from "./table.component";
var styles_TableComponent = [i0.styles];
var RenderType_TableComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TableComponent, data: {} });
export { RenderType_TableComponent as RenderType_TableComponent };
function View_TableComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "mat-header-cell", [["class", "mat-header-cell"], ["role", "columnheader"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgStyleImpl, i2.ɵNgStyleR2Impl, [i1.ElementRef, i1.KeyValueDiffers, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgStyle, [i2.ɵNgStyleImpl], { ngStyle: [0, "ngStyle"] }, null), i1.ɵdid(3, 16384, null, 0, i3.MatHeaderCell, [i4.CdkColumnDef, i1.ElementRef], null, null), (_l()(), i1.ɵted(4, null, [" ", " "])), i1.ɵppd(5, 1)], function (_ck, _v) { var _co = _v.component; var tmp_0_0 = null; var currVal_0 = (((tmp_0_0 = _co.rowColumnTemplates.get(_v.parent.context.$implicit)) == null) ? null : tmp_0_0.appColumnStyle); _ck(_v, 2, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var tmp_1_0 = null; var currVal_1 = i1.ɵunv(_v, 4, 0, _ck(_v, 5, 0, i1.ɵnov(_v.parent.parent.parent, 0), ((((tmp_1_0 = _co.rowColumnTemplates.get(_v.parent.context.$implicit)) == null) ? null : tmp_1_0.appColumnHeader) || _v.parent.context.$implicit))); _ck(_v, 4, 0, currVal_1); }); }
function View_TableComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "mat-cell", [["class", "mat-cell"], ["role", "gridcell"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(3, { "active": 0 }), i1.ɵprd(512, null, i2.ɵNgStyleImpl, i2.ɵNgStyleR2Impl, [i1.ElementRef, i1.KeyValueDiffers, i1.Renderer2]), i1.ɵdid(5, 278528, null, 0, i2.NgStyle, [i2.ɵNgStyleImpl], { ngStyle: [0, "ngStyle"] }, null), i1.ɵdid(6, 16384, null, 0, i3.MatCell, [i4.CdkColumnDef, i1.ElementRef], null, null), (_l()(), i1.ɵeld(7, 16777216, null, null, 2, null, null, null, null, null, null, null)), i1.ɵdid(8, 540672, null, 0, i2.NgTemplateOutlet, [i1.ViewContainerRef], { ngTemplateOutletContext: [0, "ngTemplateOutletContext"], ngTemplateOutlet: [1, "ngTemplateOutlet"] }, null), i1.ɵpod(9, { $implicit: 0 })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 3, 0, (_v.context.$implicit.selected && _co.isFirstColumn(_v.parent.context.$implicit))); _ck(_v, 2, 0, currVal_0); var tmp_1_0 = null; var currVal_1 = (((tmp_1_0 = _co.rowColumnTemplates.get(_v.parent.context.$implicit)) == null) ? null : tmp_1_0.appColumnStyle); _ck(_v, 5, 0, currVal_1); var currVal_2 = _ck(_v, 9, 0, _v.context.$implicit); var currVal_3 = _co.rowColumnTemplates.get(_v.parent.context.$implicit).templateRef; _ck(_v, 8, 0, currVal_2, currVal_3); }, null); }
function View_TableComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, null, null, null, null, null, null, null)), i1.ɵprd(6144, null, "MAT_SORT_HEADER_COLUMN_DEF", null, [i3.MatColumnDef]), i1.ɵdid(2, 16384, null, 3, i3.MatColumnDef, [], { name: [0, "name"] }, null), i1.ɵqud(603979776, 5, { cell: 0 }), i1.ɵqud(603979776, 6, { headerCell: 0 }), i1.ɵqud(603979776, 7, { footerCell: 0 }), i1.ɵprd(2048, [[1, 4]], i4.CdkColumnDef, null, [i3.MatColumnDef]), (_l()(), i1.ɵand(0, null, null, 2, null, View_TableComponent_3)), i1.ɵdid(8, 16384, null, 0, i3.MatHeaderCellDef, [i1.TemplateRef], null, null), i1.ɵprd(2048, [[6, 4]], i4.CdkHeaderCellDef, null, [i3.MatHeaderCellDef]), (_l()(), i1.ɵand(0, null, null, 2, null, View_TableComponent_4)), i1.ɵdid(11, 16384, null, 0, i3.MatCellDef, [i1.TemplateRef], null, null), i1.ɵprd(2048, [[5, 4]], i4.CdkCellDef, null, [i3.MatCellDef])], function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 2, 0, currVal_0); }, null); }
function View_TableComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "mat-header-row", [["class", "mat-header-row"], ["role", "row"]], null, null, null, i5.View_MatHeaderRow_0, i5.RenderType_MatHeaderRow)), i1.ɵprd(6144, null, i4.CdkHeaderRow, null, [i3.MatHeaderRow]), i1.ɵdid(2, 49152, null, 0, i3.MatHeaderRow, [], null, null)], null, null); }
function View_TableComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "mat-row", [["class", "mat-row"], ["role", "row"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.select(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_MatRow_0, i5.RenderType_MatRow)), i1.ɵprd(6144, null, i4.CdkRow, null, [i3.MatRow]), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(3, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(4, { "ignore": 0, "failed": 1 }), i1.ɵdid(5, 49152, null, 0, i3.MatRow, [], null, null)], function (_ck, _v) { var currVal_0 = _ck(_v, 4, 0, _v.context.$implicit.ignore, _v.context.$implicit.failed); _ck(_v, 3, 0, currVal_0); }, null); }
function View_TableComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 14, "mat-table", [["class", "mat-elevation-z5 mat-table"]], null, null, null, i5.View_MatTable_0, i5.RenderType_MatTable)), i1.ɵprd(6144, null, i4.CdkTable, null, [i3.MatTable]), i1.ɵdid(2, 2342912, null, 4, i3.MatTable, [i1.IterableDiffers, i1.ChangeDetectorRef, i1.ElementRef, [8, null], [2, i6.Directionality], i2.DOCUMENT, i7.Platform], { dataSource: [0, "dataSource"] }, null), i1.ɵqud(603979776, 1, { _contentColumnDefs: 1 }), i1.ɵqud(603979776, 2, { _contentRowDefs: 1 }), i1.ɵqud(603979776, 3, { _contentHeaderRowDefs: 1 }), i1.ɵqud(603979776, 4, { _contentFooterRowDefs: 1 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TableComponent_2)), i1.ɵdid(8, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(0, null, null, 2, null, View_TableComponent_5)), i1.ɵdid(10, 540672, null, 0, i3.MatHeaderRowDef, [i1.TemplateRef, i1.IterableDiffers], { columns: [0, "columns"] }, null), i1.ɵprd(2048, [[3, 4]], i4.CdkHeaderRowDef, null, [i3.MatHeaderRowDef]), (_l()(), i1.ɵand(0, null, null, 2, null, View_TableComponent_6)), i1.ɵdid(13, 540672, null, 0, i3.MatRowDef, [i1.TemplateRef, i1.IterableDiffers], { columns: [0, "columns"] }, null), i1.ɵprd(2048, [[2, 4]], i4.CdkRowDef, null, [i3.MatRowDef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.matDataSource; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.displayedColumns; _ck(_v, 8, 0, currVal_1); var currVal_2 = _co.displayedColumns; _ck(_v, 10, 0, currVal_2); var currVal_3 = _co.displayedColumns; _ck(_v, 13, 0, currVal_3); }, null); }
export function View_TableComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i8.UpperFirstPipe, []), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TableComponent_1)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.matDataSource; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_TableComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "app-table", [], null, null, null, View_TableComponent_0, RenderType_TableComponent)), i1.ɵdid(1, 1294336, null, 1, i9.TableComponent, [], null, null), i1.ɵqud(603979776, 1, { rowColumnDirectives: 1 })], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TableComponentNgFactory = i1.ɵccf("app-table", i9.TableComponent, View_TableComponent_Host_0, { dataSource: "dataSource", displayedColumns: "displayedColumns", filter: "filter", disableSelection: "disableSelection" }, { disableSelectionChange: "disableSelectionChange", itemSelect: "itemSelect" }, []);
export { TableComponentNgFactory as TableComponentNgFactory };
