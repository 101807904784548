import * as tslib_1 from "tslib";
import { OnDestroy, OnInit } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { Subscription } from 'rxjs';
export class TherapyTypeComponent {
    constructor(therapyService) {
        this.therapyService = therapyService;
        this.therapyTypes = [];
        this._ngUnsubscribe = new Subscription();
    }
    ngOnInit() {
        if (this.therapyControl) {
            this._ngUnsubscribe.add(this.therapyControl.valueChanges.subscribe(() => {
                this.initTherapyTypes();
            }));
            this.initTherapyTypes();
        }
    }
    initTherapyTypes() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const value = this.therapyControl.value;
            this.select.value = null;
            this.therapyTypes = [];
            if (value === null || value === undefined) {
                return;
            }
            this.therapyTypes = yield this.therapyService.getTherapyTypeItems(value);
        });
    }
    ngOnDestroy() {
        this._ngUnsubscribe.unsubscribe();
    }
    writeValue(obj) {
        this.select.value = obj;
    }
    registerOnChange(fn) {
        this.select.registerOnChange(fn);
    }
    registerOnTouched(fn) {
        this.select.registerOnTouched(fn);
    }
    setDisabledState(isDisabled) {
        this.select.disabled = isDisabled;
    }
}
