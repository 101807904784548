import { Component, forwardRef, Input, ViewChild } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatSelect } from '@angular/material';

@Component({
  selector: 'app-dms-module',
  templateUrl: './dms-module.component.html',
  styleUrls: ['./dms-module.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DmsModuleComponent),
      multi: true,
    },
  ],
})
export class DmsModuleComponent {
  @ViewChild('select', { static: true }) select: MatSelect;
  @Input()
  public dmsModules: string[];

  constructor() {}

  writeValue(obj: any): void {
    this.select.value = obj;
  }
  registerOnChange(fn: any): void {
    this.select.registerOnChange(fn);
  }
  registerOnTouched(fn: any): void {
    this.select.registerOnTouched(fn);
  }
  setDisabledState?(isDisabled: boolean): void {
    this.select.disabled = isDisabled;
  }
}
