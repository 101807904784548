import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import moment from 'moment';
import { LoginModule } from 'src/app/login/login.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { TemplateModule } from 'src/app/template/template.module';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

moment.locale('de');
@NgModule({
  declarations: [AppComponent],
  imports: [BrowserAnimationsModule, AppRoutingModule, HttpClientModule, LoginModule, TemplateModule, SharedModule],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
