import {
  AfterContentInit,
  Component,
  ContentChildren,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
} from '@angular/core';
import { MatTableDataSource } from '@angular/material';
import { Observable, Subscription } from 'rxjs';

import { ArrayHelper } from '../../helper/array.helper';
import { TableRowColumnDirective } from './table-row-column.directive';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
})
export class TableComponent implements OnInit, AfterContentInit, OnDestroy {
  @ContentChildren(TableRowColumnDirective) rowColumnDirectives: QueryList<TableRowColumnDirective>;
  @Input() dataSource: Observable<any[]>;
  @Input() displayedColumns: string[] = [];
  @Input()
  set filter(filterValue: string) {
    this._currentFilter = filterValue;
    if (filterValue) {
      this.matDataSource.filter = filterValue.trim().toLowerCase();
    } else {
      if (this.matDataSource) {
        this.matDataSource.filter = undefined;
      }
    }
  }
  @Input() disableSelection = false;
  @Output() disableSelectionChange = new EventEmitter();
  @Output() itemSelect = new EventEmitter();

  public matDataSource: MatTableDataSource<any>;

  public rowColumnTemplates: Map<string, TableRowColumnDirective>;
  private _dataSourceSubscription: Subscription;
  private _currentFilter: string;

  constructor() {}

  ngOnInit() {
    this._dataSourceSubscription = this.dataSource.subscribe((dataSource) => {
      if (!dataSource) {
        this.matDataSource = undefined;
        return;
      }
      this.matDataSource = new MatTableDataSource(dataSource);
      this.matDataSource.filter = this._currentFilter;
    });
  }

  ngAfterContentInit() {
    this.rowColumnTemplates = ArrayHelper.toMap(
      this.rowColumnDirectives.toArray(),
      (val: TableRowColumnDirective) => val.appRowColumn
    );
  }

  ngOnDestroy() {
    this._dataSourceSubscription.unsubscribe();
  }

  select(item) {
    if (this.disableSelection) {
      return;
    }
    this.disableSelection = true;
    this.disableSelectionChange.emit(true);
    this.matDataSource.data = this.matDataSource.data.map((dataItem: any) => {
      if (item._id === dataItem._id) {
        dataItem.selected = true;
      } else {
        dataItem.selected = false;
      }
      return dataItem;
    });
    this.itemSelect.emit(item);
  }

  unselectAll() {
    if (this.matDataSource && this.matDataSource.data) {
      this.matDataSource.data = this.matDataSource.data.map((dataItem: any) => {
        dataItem.selected = false;
        return dataItem;
      });
    }
    this.disableSelection = false;
    this.disableSelectionChange.emit(false);
  }

  isFirstColumn = (columnName: string) => {
    return this.displayedColumns.indexOf(columnName) === 0;
  };
}
