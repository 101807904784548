import { BaseService } from './base.service';
import { FeathersService } from './feathers.service';
import { Injectable } from '@angular/core';
import { Config } from '../models/config.model';

@Injectable({
  providedIn: 'root',
})
export class ConfigService extends BaseService<Config> {
  constructor(feathers: FeathersService) {
    super('config', feathers.app);
  }
}
