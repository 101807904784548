import { Component, forwardRef, OnInit, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatSelect } from '@angular/material';

import { Therapies } from '../../shared/models/therapy.model';
import { TherapyService } from 'src/app/shared/services/therapy.service';

@Component({
  selector: 'app-therapy',
  templateUrl: './therapy.component.html',
  styleUrls: ['./therapy.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TherapyComponent),
      multi: true,
    },
  ],
})
export class TherapyComponent implements OnInit, ControlValueAccessor {
  @ViewChild('select', { static: true }) select: MatSelect;

  public therapies = [];

  constructor(private therapyService: TherapyService) {}

  async ngOnInit() {
    this.therapies = await this.therapyService.getTherapyItems();
  }

  writeValue(obj: any): void {
    this.select.value = obj;
  }
  registerOnChange(fn: any): void {
    this.select.registerOnChange(fn);
  }
  registerOnTouched(fn: any): void {
    this.select.registerOnTouched(fn);
  }
  setDisabledState?(isDisabled: boolean): void {
    this.select.disabled = isDisabled;
  }
}
