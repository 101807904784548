import * as tslib_1 from "tslib";
export class HeaderComponent {
    constructor(fullscreenService, authService) {
        this.fullscreenService = fullscreenService;
        this.authService = authService;
        this.authService.isAuthenticated.subscribe(isLogedIn => {
            this.showLogOutButton = isLogedIn;
        });
    }
    logout() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            yield this.authService.logout();
            window.location.reload();
        });
    }
}
