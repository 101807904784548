import * as tslib_1 from "tslib";
import { v4 } from 'uuid';
export class BaseService {
    constructor(serviceName, app) {
        this.cache = [];
        this._service = app.service(serviceName);
    }
    find(params, options) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (this._fetchingPromise) {
                return this._fetchingPromise;
            }
            this._fetchingPromise = new Promise((_resolve, _reject) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                try {
                    params = params || { query: {} };
                    params.query = params.query || {};
                    let items = [];
                    let count = 0;
                    let total = 1;
                    let limit = 100;
                    while (count < total) {
                        params.query["$limit"] = limit;
                        params.query["$skip"] = count;
                        const paginatedResult = (yield this._service.find(params));
                        items = items.concat(paginatedResult.data);
                        total = paginatedResult.total;
                        limit = paginatedResult.limit;
                        count += limit;
                    }
                    if (options && options.noCaching) {
                        _resolve(items);
                        return;
                    }
                    this.cache.splice(0, this.cache.length);
                    this.cache.push(...items);
                    _resolve(this.cache);
                }
                catch (e) {
                    console.error(e);
                    _reject(e);
                }
            }));
            const val = yield this._fetchingPromise;
            this._fetchingPromise = undefined;
            return Promise.resolve(val);
        });
    }
    get(id, params, options) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (options && options.noCaching) {
                return this._service.get(id, params);
            }
            const item = this.cache.find((f) => f._id === id || f.id === id);
            if (item) {
                return Promise.resolve(item);
            }
            return this._service.get(id, params);
        });
    }
    create(data, params, options) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                if (!data.hasOwnProperty("_id")) {
                    data["_id"] = v4();
                }
                const item = yield this._service.create(data, params);
                if (options && options.noCaching) {
                    return Promise.resolve(item);
                }
                this.cache.push(item);
                return Promise.resolve(item);
            }
            catch (e) {
                return Promise.reject(e);
            }
        });
    }
    update(id, data, params, options) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const item = yield this._service.update(id, data, params);
                if (options && options.noCaching) {
                    return Promise.resolve(item);
                }
                const index = this.cache.findIndex(f => f._id === item._id);
                if (index > -1) {
                    this.cache[index] = item;
                }
                return Promise.resolve(item);
            }
            catch (e) {
                return Promise.reject(e);
            }
        });
    }
    patch(id, data, params, options) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const item = yield this._service.patch(id, data, params);
                if (options && options.noCaching) {
                    return Promise.resolve(item);
                }
                const index = this.cache.findIndex(f => f._id === item._id);
                if (index > -1) {
                    this.cache[index] = item;
                }
                return Promise.resolve(item);
            }
            catch (e) {
                return Promise.reject(e);
            }
        });
    }
    remove(id, params, options) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const item = yield this._service.remove(id, params);
                if (options && options.noCaching) {
                    return Promise.resolve(item);
                }
                const index = this.cache.findIndex(f => f._id === id);
                this.cache.splice(index, 1);
                return Promise.resolve(item);
            }
            catch (e) {
                return Promise.reject(e);
            }
        });
    }
}
