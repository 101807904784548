import { Injectable } from '@angular/core';
import { BaseService } from 'src/app/shared/services/base.service';

import { Template } from '../shared/models/template.model';
import { FeathersService } from '../shared/services/feathers.service';

@Injectable({
  providedIn: "root"
})
export class TemplateService extends BaseService<Template> {
  constructor(feathers: FeathersService) {
    super("template", feathers.app);
  }
}
