import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import authentication from '@feathersjs/authentication-client';
import feathers, { Application } from '@feathersjs/feathers';
import restClient from '@feathersjs/rest-client';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: "root"
})
export class FeathersService {
  private _app: Application;
  public get app(): Application {
    return this._app;
  }
  constructor(httpClient: HttpClient) {
    const options = { HttpHeaders };
    this._app = feathers();
    this._app.configure(
      restClient(environment.apiUrl).angularHttpClient(httpClient, options)
    );
    this._app.configure(authentication());
  }
}
