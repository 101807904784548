import { Subject } from 'rxjs';
import { DeleteDialogComponent } from './delete-dialog.component';
import * as i0 from "@angular/core";
import * as i1 from "@angular/material/dialog";
export class DeleteDialogService {
    constructor(_dialog) {
        this._dialog = _dialog;
        this._afterDelConfirmSubject = new Subject();
        this.afterDelConfirm = this._afterDelConfirmSubject.asObservable();
    }
    show(entityName, entityTitle, confirmWithInput = true) {
        const subscription = this._dialog
            .open(DeleteDialogComponent, {
            width: '400px',
            maxHeight: '800px',
            data: { entityName, entityTitle, confirmWithInput },
        })
            .afterClosed()
            .subscribe(result => {
            if (result) {
                this._afterDelConfirmSubject.next(result);
            }
            subscription.unsubscribe();
        });
    }
}
DeleteDialogService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function DeleteDialogService_Factory() { return new DeleteDialogService(i0.ɵɵinject(i1.MatDialog)); }, token: DeleteDialogService, providedIn: "root" });
