import { map, take } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "../shared/services/auth.service";
export class NoAuthGuard {
    constructor(_authService) {
        this._authService = _authService;
    }
    canActivate(route, state) {
        return this._authService.isAuthenticated.pipe(take(1), map(bool => !bool));
    }
}
NoAuthGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function NoAuthGuard_Factory() { return new NoAuthGuard(i0.ɵɵinject(i1.AuthService)); }, token: NoAuthGuard, providedIn: "root" });
