import { Injectable } from '@angular/core';
import { DocumentGenerator } from '@pia/pia.swodoc.document-generator';
import { EmptyAssetLoader } from './asset-loader';
import { KeyValueGenerator } from './key-value-Generator';

@Injectable({
  providedIn: 'root',
})
export class AuditReportService {
  public async prepareDataForReport(audit) {
    const documentGenerator = new DocumentGenerator(new EmptyAssetLoader(), new KeyValueGenerator());
    const data = await documentGenerator.generate(audit, { allConitionsTrue: true });
    console.log(data);
    return data;
  }
}
