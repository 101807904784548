import { Component, forwardRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatSelect } from '@angular/material';
import { Subscription } from 'rxjs';
import { TherapyTypeItem } from 'src/app/shared/models/therapy-type-item.model';
import { Therapies } from 'src/app/shared/models/therapy.model';
import { TherapyService } from 'src/app/shared/services/therapy.service';

@Component({
  selector: 'app-therapy-type',
  templateUrl: './therapy-type.component.html',
  styleUrls: ['./therapy-type.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TherapyTypeComponent),
      multi: true,
    },
  ],
})
export class TherapyTypeComponent implements OnInit, OnDestroy {
  @ViewChild('select', { static: true }) select: MatSelect;
  @Input() public therapyControl: AbstractControl;
  public therapyTypes: TherapyTypeItem[] = [];
  private _ngUnsubscribe = new Subscription();
  constructor(private therapyService: TherapyService) {}

  ngOnInit() {
    if (this.therapyControl) {
      this._ngUnsubscribe.add(
        this.therapyControl.valueChanges.subscribe(() => {
          this.initTherapyTypes();
        })
      );
      this.initTherapyTypes();
    }
  }

  private async initTherapyTypes() {
    const value = this.therapyControl.value;
    this.select.value = null;
    this.therapyTypes = [];
    if (value === null || value === undefined) {
      return;
    }
    this.therapyTypes = await this.therapyService.getTherapyTypeItems(value);
  }

  ngOnDestroy() {
    this._ngUnsubscribe.unsubscribe();
  }

  writeValue(obj: any): void {
    this.select.value = obj;
  }
  registerOnChange(fn: any): void {
    this.select.registerOnChange(fn);
  }
  registerOnTouched(fn: any): void {
    this.select.registerOnTouched(fn);
  }
  setDisabledState?(isDisabled: boolean): void {
    this.select.disabled = isDisabled;
  }
}
