import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { v4 } from 'uuid';
import { TemplateViewModel } from '../../shared/models/template.view.model';
import { BasicInfoFormMode } from '../template-basic-info-form/template-basic-info-form.component';
export class CopyTemplateDialogComponent {
    constructor(dialogRef, data, _templateService, 
    // private _copyService: CopyService
    _reportService, _matchService) {
        this.dialogRef = dialogRef;
        this.data = data;
        this._templateService = _templateService;
        this._reportService = _reportService;
        this._matchService = _matchService;
        if (!this.data || !this.data.template) {
            throw new Error('Template is missing');
        }
        this.template = Object.assign(new TemplateViewModel(), this.data.template);
        if (this.template.entityTypes.length === 1 && this.template.entityTypes[0] === 'patient') {
            this.templateEditorMode = BasicInfoFormMode.patient;
        }
        else {
            this.templateEditorMode = BasicInfoFormMode.institution;
        }
    }
    ngOnInit() {
        this.duplicateReport = this.template.report !== undefined;
        this.duplicateMatch = this.template.match !== undefined;
        this.template.title += ' (Copy)';
        // Reset template version of duplicate to 0 (= first version, 1 in html view)
        this.template.version = 0;
    }
    copy() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.busy = true;
            try {
                const origId = this.template._id;
                this.template._id = v4();
                this.template.originTemplateId = origId;
                this.template.draft = true;
                const result = yield this._templateService.create(this.template);
                if (result) {
                    if (this.duplicateReport) {
                        // await this._copyService.copy({
                        //   id: origId,
                        //   targetId: this.template._id,
                        //   sourceCollection: "report",
                        //   targetCollection: "report",
                        //   sourceDb: this.data.tenant.tenantId,
                        //   targetDb: this.data.tenant.tenantId
                        // });
                        const reportEntity = yield this._reportService.get(origId, undefined, { noCaching: true });
                        reportEntity._id = this.template._id;
                        yield this._reportService.create(reportEntity);
                    }
                    if (this.duplicateMatch) {
                        // await this._copyService.copy({
                        //   id: origId,
                        //   targetId: this.template._id,
                        //   sourceCollection: "match",
                        //   targetCollection: "match",
                        //   sourceDb: this.data.tenant.tenantId,
                        //   targetDb: this.data.tenant.tenantId
                        // });
                        const matchEntity = yield this._matchService.get(origId, undefined, {
                            noCaching: true,
                        });
                        matchEntity._id = this.template._id;
                        yield this._matchService.create(matchEntity);
                    }
                }
                this.dialogRef.close(true);
            }
            catch (e) {
                this.error = e.message;
            }
            finally {
                this.busy = false;
            }
        });
    }
}
