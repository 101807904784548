<h1 mat-dialog-title>
  Archivieren?
</h1>

<mat-dialog-content>
  Bist du sicher, dass du {{ data.entityName }} <b>{{ data.entityTitle }}</b> archivieren möchtest?
  <div *ngIf="data.confirmWithInput">
    <br />
    Bitte bestätige das Archivieren, indem du den Namen hier wiederholst:
    <mat-form-field appearance="standard">
      <!-- <mat-label>Title</mat-label> -->
      <input matInput [placeholder]="data.entityTitle" [(ngModel)]="confirmInput" />
    </mat-form-field>
  </div>
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-button color="accent" (click)="dialogRef.close()"><mat-icon>cancel</mat-icon>Abbrechen</button>
  <button
    mat-button
    color="warn"
    (click)="dialogRef.close(true)"
    [disabled]="data.confirmWithInput && data.entityTitle !== confirmInput"
    style="display: flex;"
  >
    <mat-icon>delete</mat-icon>Archivieren
  </button>
</mat-dialog-actions>
