import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';

@Pipe({
  name: 'updatedAt',
})
export class UpdatedAtPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    if (value) {
      const momentDate = moment(value);
      if (momentDate.isValid()) {
        return momentDate.format('DD.MM.YYYY HH:mm [Uhr]');
      }
    }
  }
}
