<form [formGroup]="createTemplateForm">
  <mat-form-field>
    <input matInput placeholder="Name" formControlName="title" required />
  </mat-form-field>

  <mat-form-field *ngIf="mode === basicInfoFormModeEnum.institution">
    <mat-label>Institutionstypen</mat-label>
    <mat-select formControlName="entityTypes" multiple required>
      <mat-option value="nursingHome">Pflegeheim</mat-option>
      <mat-option value="doctor">Arzt</mat-option>
      <mat-option value="nursingService">Pflegedienst</mat-option>
      <mat-option value="hospital">Krankenhaus</mat-option>
      <mat-option value="pharmacy">Apotheke</mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field *ngIf="mode === basicInfoFormModeEnum.institution">
    <input
      type="text"
      placeholder="Gruppierungsebene"
      matInput
      formControlName="groupingText"
      [matAutocomplete]="groupingTextAuto"
    />
    <mat-autocomplete #groupingTextAuto="matAutocomplete">
      <mat-option *ngFor="let option of groupingTextsOptions" [value]="option">
        {{ option }}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>

  <mat-form-field>
    <input readonly="true" matInput placeholder="Version" formControlName="version" />
  </mat-form-field>

  <mat-form-field>
    <input matInput placeholder="Beschreibung" formControlName="description" />
  </mat-form-field>

  <mat-form-field>
    <input
      type="text"
      placeholder="Dokumententyp"
      matInput
      formControlName="templateTag"
      [matAutocomplete]="templateTagAuto"
    />
    <mat-autocomplete #templateTagAuto="matAutocomplete">
      <mat-option *ngFor="let option of templateTagOptions" [value]="option">
        {{ option }}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>

  <app-therapy
    formControlName="therapyId"
    *ngIf="mode === basicInfoFormModeEnum.patient || mode === basicInfoFormModeEnum.careDocuments"
  ></app-therapy>

  <app-therapy-type
    *ngIf="mode === basicInfoFormModeEnum.patient || mode === basicInfoFormModeEnum.careDocuments"
    formControlName="therapyTypeId"
    [therapyControl]="createTemplateForm.get('therapyId')"
  ></app-therapy-type>

  <mat-slide-toggle *ngIf="showSendEmailOnComplete" formControlName="sendEmailOnComplete">
    E-Mails beim Abschließen abfragen
  </mat-slide-toggle>

  <mat-slide-toggle formControlName="disableReport"> Bericht deaktivieren </mat-slide-toggle>

  <mat-slide-toggle formControlName="sendToOcr"> An OCR schicken </mat-slide-toggle>

  <app-payer formControlName="payer" *ngIf="mode === basicInfoFormModeEnum.patient"></app-payer>
  <mat-accordion [@.disabled]="disableAnimation">
    <mat-expansion-panel
      [expanded]="false"
      *ngIf="dmsDocumentTypes || dmsAccessLists || dmsFieldMappings || dmsModules"
    >
      <mat-expansion-panel-header>
        <mat-panel-title> DMS Mapping </mat-panel-title>
      </mat-expansion-panel-header>

      <app-dms-fieldmapping
        *ngIf="dmsFieldMappings"
        [dmsFieldMappings]="dmsFieldMappings"
        formControlName="dmsFieldmapping"
      ></app-dms-fieldmapping>
      <app-dms-document-type
        *ngIf="dmsDocumentTypes"
        [dmsDocumentTypes]="dmsDocumentTypes"
        formControlName="dmsDocumentType"
      ></app-dms-document-type>
      <app-dms-access-list
        *ngIf="dmsAccessLists"
        [dmsAccessLists]="dmsAccessLists"
        formControlName="dmsAccessList"
      ></app-dms-access-list>
      <app-dms-module *ngIf="dmsModules" [dmsModules]="dmsModules" formControlName="dmsModule"></app-dms-module>
    </mat-expansion-panel>
  </mat-accordion>
</form>
