import { Injectable } from '@angular/core';
import { BaseService } from 'src/app/shared/services/base.service';

import { Match } from '../models/match.model';
import { FeathersService } from './feathers.service';

@Injectable({
  providedIn: "root"
})
export class MatchService extends BaseService<Match> {
  constructor(feathers: FeathersService) {
    super("match", feathers.app);
  }
}
