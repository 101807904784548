import {
  IPermissions,
  IResponseSet,
  ITemplate,
  ITemplateNode,
  ITemplateWebHookUrl,
  TemplateType,
} from '@pia/pia.shared';

import { MongoModel } from './_mongo.model';

export class Template extends MongoModel implements ITemplate {
  webhook: ITemplateWebHookUrl;
  sendToOcr: boolean;
  version: number;
  sendEmailOnComplete: boolean;
  disableReport: boolean;
  originTemplateId: string;
  entityTypes: string[];
  groupingText: string;
  templateTag: string;
  payer: string[];
  tags: string[];
  createdUtc: Date;
  updateUtc: Date;
  permissions: IPermissions;
  revision: string;
  dmsDocumentType?: string;
  dmsAccessList?: string;
  dmsFieldmapping?: string;
  dmsModule?: string;
  documentNo: number;
  documentNoFormat: string;
  defaultAuditDisplayType: number;
  responseSets: IResponseSet[];
  templateNodes: ITemplateNode[];
  archived: boolean;
  title: string;
  description: string;
  type: TemplateType;
  therapyId?: number;
  therapyTypeId?: number;
  draft?: boolean;
}
