<mat-form-field>
  <mat-select placeholder="Therapieart" #select>
    <mat-option>- Keine -</mat-option>
    <mat-option
      *ngFor="let therapyType of therapyTypes"
      [value]="therapyType.id"
    >
      {{ therapyType.displayName }}
    </mat-option>
  </mat-select>
</mat-form-field>
