<app-spinner *ngIf="showSpinner"></app-spinner>

<mat-list #matchList [style.display]="showSpinner ? 'none' : 'block'">
  <mat-list-item *ngFor="let field of matchFields">
    <mat-form-field>
      <mat-select placeholder="swodoc" [(value)]="field.swodoc">
        <mat-option>- Keine -</mat-option>
        <mat-optgroup *ngFor="let section of templateNodes.sections" [label]="section.title">
          <mat-option *ngFor="let node of section.nodes" [value]="node._id" [disabled]="itemDisabled(node._id)">
            {{ node.title }} <ng-container *ngIf="node.name">- {{ node.name }} </ng-container>
            <small style="color: lightgrey">({{ node.type }})</small>
          </mat-option>
        </mat-optgroup>
      </mat-select>
    </mat-form-field>
    <mat-form-field>
      <mat-select placeholder="alberta" [compareWith]="compareFn" [(value)]="field.alberta">
        <mat-option>- Keine -</mat-option>
        <mat-option *ngFor="let field of albertaMatchFields" [value]="field">
          {{ field.title }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <button mat-icon-button (click)="removeMatchField(field)">
      <mat-icon>delete</mat-icon>
    </button>
  </mat-list-item>
</mat-list>
<button mat-button (click)="addMatchField()" class="add-button" color="primary">
  <mat-icon>add</mat-icon>Zurodnung hinzufügen
</button>
