import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { Router } from '@angular/router';

import { AuthService } from '../shared/services/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  public username = '';
  public password: string;
  public showSpinner = false;
  public showPassword: boolean;
  constructor(private _auth: AuthService, private _router: Router, public snackBar: MatSnackBar) {}

  ngOnInit() {}

  public async login() {
    this.snackBar.dismiss();
    try {
      this.showSpinner = true;
      await this._auth.login(this.username, this.password);
      this.showSpinner = false;
      this._router.navigate(['']);
    } catch (e) {
      this.showSpinner = false;
      this.snackBar.open(e.message, undefined, {
        duration: 3000,
      });
    }
  }
}
