import { ITherapy } from '@pia/pia.shared';
import { Injectable } from '@angular/core';
import { cloneDeep } from 'lodash';

import { Config } from '../models/config.model';
import { TherapyItem } from '../models/therapy-item.model';
import { TherapyTypeItem } from '../models/therapy-type-item.model';
import { Therapies } from '../models/therapy.model';
import { ConfigService } from './config.service';

@Injectable({ providedIn: 'root' })
export class TherapyService {
  private config: Config;
  constructor(private configService: ConfigService) {}

  public async getTherapyItems(): Promise<TherapyItem[]> {
    await this.loadConfig();

    if (!this.config || !this.config.therapies) {
      return [];
    }

    const tenantTherapies = this.config.therapies;
    const tenantTherapyItems: TherapyItem[] = [];

    Therapies.complete
      .filter((therapyItem) => tenantTherapies.some((therapy) => therapy.therapy === therapyItem.id))
      .forEach((therapyItem) => {
        const clonedTherapyItem = cloneDeep(therapyItem);
        clonedTherapyItem.therapyTypes = this.filterTherapyTypeItems(tenantTherapies, clonedTherapyItem.id);
        tenantTherapyItems.push(clonedTherapyItem);
      });

    return tenantTherapyItems;
  }

  public async getTherapyTypeItems(therapyId: number): Promise<TherapyTypeItem[]> {
    await this.loadConfig();

    if (!this.config || !this.config.therapies) {
      return [];
    }
    return this.filterTherapyTypeItems(this.config.therapies, therapyId);
  }

  private filterTherapyTypeItems(tenantTherapies: ITherapy[], therapyId: number) {
    const tenantTherapyTypes = tenantTherapies.find((t) => t.therapy === therapyId).therapyTypes || [];
    const therapy = Therapies.complete.find((t) => t.id === therapyId);
    if (therapy) {
      return therapy.therapyTypes.filter((therapyType) => tenantTherapyTypes.some((type) => type === therapyType.id));
    } else {
      return [];
    }
  }

  private async loadConfig(): Promise<void> {
    if (!this.config) {
      const configs = (await this.configService.find()) as Config[];
      if (!configs || !configs.length) {
        return null;
      }
      this.config = configs[0];
    }
  }
}
