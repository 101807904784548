import { Application, Id, NullableId, Paginated, Params, Service, ServiceMethods } from '@feathersjs/feathers';
import { v4 } from 'uuid';

import { ServiceOptions } from '../models/service.options.model';

export class BaseService<T> implements ServiceMethods<T> {
  protected readonly _service: Service<T>;
  public readonly cache: T[] = [];
  private _fetchingPromise: Promise<T[]>;
  constructor(serviceName: string, app: Application) {
    this._service = app.service(serviceName);
  }

  async find(
    params?: Params,
    options?: ServiceOptions
  ): Promise<T[] | Paginated<T>> {
    if (this._fetchingPromise) {
      return this._fetchingPromise;
    }
    this._fetchingPromise = new Promise(async (_resolve, _reject) => {
      try {
        params = params || { query: {} };
        params.query = params.query || {};
        let items = [];
        let count = 0;
        let total = 1;
        let limit = 100;
        while (count < total) {
          params.query["$limit"] = limit;
          params.query["$skip"] = count;
          const paginatedResult = (await this._service.find(
            params
          )) as Paginated<T>;
          items = items.concat(paginatedResult.data);
          total = paginatedResult.total;
          limit = paginatedResult.limit;
          count += limit;
        }

        if (options && options.noCaching) {
          _resolve(items);
          return;
        }
        this.cache.splice(0, this.cache.length);
        this.cache.push(...items);
        _resolve(this.cache);
      } catch (e) {
        console.error(e);
        _reject(e);
      }
    });
    const val = await this._fetchingPromise;
    this._fetchingPromise = undefined;
    return Promise.resolve(val);
  }

  async get(id: Id, params?: Params, options?: ServiceOptions): Promise<T> {
    if (options && options.noCaching) {
      return this._service.get(id, params);
    }
    const item = this.cache.find((f: any) => f._id === id || f.id === id);
    if (item) {
      return Promise.resolve(item);
    }
    return this._service.get(id, params);
  }

  async create(
    data: Partial<T> | Array<Partial<T>>,
    params?: Params,
    options?: ServiceOptions
  ): Promise<T | T[]> {
    try {
      if (!data.hasOwnProperty("_id")) {
        data["_id"] = v4();
      }
      const item = await this._service.create(data, params);
      if (options && options.noCaching) {
        return Promise.resolve(item);
      }
      this.cache.push(item as any);
      return Promise.resolve(item);
    } catch (e) {
      return Promise.reject(e);
    }
  }

  async update(
    id: NullableId,
    data: T,
    params?: Params,
    options?: ServiceOptions
  ): Promise<T> {
    try {
      const item = await this._service.update(id, data, params);
      if (options && options.noCaching) {
        return Promise.resolve(item);
      }
      const index = this.cache.findIndex(
        f => (f as any)._id === (item as any)._id
      );
      if (index > -1) {
        this.cache[index] = item;
      }
      return Promise.resolve(item);
    } catch (e) {
      return Promise.reject(e);
    }
  }

  async patch(
    id: NullableId,
    data: Partial<T>,
    params?: Params,
    options?: ServiceOptions
  ): Promise<T> {
    try {
      const item = await this._service.patch(id, data, params);
      if (options && options.noCaching) {
        return Promise.resolve(item);
      }
      const index = this.cache.findIndex(
        f => (f as any)._id === (item as any)._id
      );
      if (index > -1) {
        this.cache[index] = item;
      }
      return Promise.resolve(item);
    } catch (e) {
      return Promise.reject(e);
    }
  }

  async remove(
    id: NullableId,
    params?: Params,
    options?: ServiceOptions
  ): Promise<T> {
    try {
      const item = await this._service.remove(id, params);
      if (options && options.noCaching) {
        return Promise.resolve(item);
      }
      const index = this.cache.findIndex(f => (f as any)._id === id);
      this.cache.splice(index, 1);
      return Promise.resolve(item);
    } catch (e) {
      return Promise.reject(e);
    }
  }
}
