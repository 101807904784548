import { Subject } from 'rxjs';
import * as i0 from "@angular/core";
export class FullscreenService {
    constructor() {
        this._fullscreenSubject = new Subject();
        this.fullscreen = this._fullscreenSubject.asObservable();
    }
    toggle() {
        this._fullscreen = !this._fullscreen;
        this._fullscreenSubject.next(this._fullscreen);
    }
    set(value) {
        this._fullscreen = value;
        this._fullscreenSubject.next(this._fullscreen);
    }
}
FullscreenService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function FullscreenService_Factory() { return new FullscreenService(); }, token: FullscreenService, providedIn: "root" });
