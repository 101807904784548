import * as tslib_1 from "tslib";
import { cloneDeep } from 'lodash';
import { Therapies } from '../models/therapy.model';
import * as i0 from "@angular/core";
import * as i1 from "./config.service";
export class TherapyService {
    constructor(configService) {
        this.configService = configService;
    }
    getTherapyItems() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            yield this.loadConfig();
            if (!this.config || !this.config.therapies) {
                return [];
            }
            const tenantTherapies = this.config.therapies;
            const tenantTherapyItems = [];
            Therapies.complete
                .filter((therapyItem) => tenantTherapies.some((therapy) => therapy.therapy === therapyItem.id))
                .forEach((therapyItem) => {
                const clonedTherapyItem = cloneDeep(therapyItem);
                clonedTherapyItem.therapyTypes = this.filterTherapyTypeItems(tenantTherapies, clonedTherapyItem.id);
                tenantTherapyItems.push(clonedTherapyItem);
            });
            return tenantTherapyItems;
        });
    }
    getTherapyTypeItems(therapyId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            yield this.loadConfig();
            if (!this.config || !this.config.therapies) {
                return [];
            }
            return this.filterTherapyTypeItems(this.config.therapies, therapyId);
        });
    }
    filterTherapyTypeItems(tenantTherapies, therapyId) {
        const tenantTherapyTypes = tenantTherapies.find((t) => t.therapy === therapyId).therapyTypes || [];
        const therapy = Therapies.complete.find((t) => t.id === therapyId);
        if (therapy) {
            return therapy.therapyTypes.filter((therapyType) => tenantTherapyTypes.some((type) => type === therapyType.id));
        }
        else {
            return [];
        }
    }
    loadConfig() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (!this.config) {
                const configs = (yield this.configService.find());
                if (!configs || !configs.length) {
                    return null;
                }
                this.config = configs[0];
            }
        });
    }
}
TherapyService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function TherapyService_Factory() { return new TherapyService(i0.ɵɵinject(i1.ConfigService)); }, token: TherapyService, providedIn: "root" });
