import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { groupBy } from 'lodash';
import { Subscription } from 'rxjs';
import { Config } from 'src/app/shared/models/config.model';
import { AuthService } from 'src/app/shared/services/auth.service';
import { ConfigService } from 'src/app/shared/services/config.service';

import { Template } from '../../shared/models/template.model';
import { TemplateService } from '../template.service';

export enum BasicInfoFormMode {
  patient,
  institution,
  careDocuments,
  crossTherapy,
}

@Component({
  selector: 'app-template-basic-info-form',
  templateUrl: './template-basic-info-form.component.html',
  styleUrls: ['./template-basic-info-form.component.scss'],
})
export class TemplateBasicInfoFormComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() template: Template;
  @Input() mode: any;
  @Output() templateChange = new EventEmitter<Template>();
  @Output() valid = new EventEmitter<boolean>();

  createTemplateForm: FormGroup;
  public templateTagOptions: string[] = [];
  public groupingTextsOptions: string[] = [];
  public basicInfoFormModeEnum = BasicInfoFormMode;
  private _ngUnsubscribe = new Subscription();
  private allTemplates: string[] = [];
  private allGroupingTexts: string[] = [];
  private allTemplats: string[] = [];
  private config: Config;
  public dmsDocumentTypes: string[];
  public dmsFieldMappings: string[];
  public dmsModules: string[];
  public dmsAccessLists: string[];

  get showSendEmailOnComplete(): boolean {
    const user = this.authService.currentUser;
    return user && (user.email.includes('resmed') || user.email.includes('it-labs'));
  }

  constructor(
    private fb: FormBuilder,
    private _templateService: TemplateService,
    private configService: ConfigService,
    private authService: AuthService
  ) {}

  // Workaround for angular component issue #13870
  public disableAnimation = true;
  ngAfterViewInit(): void {
    // timeout required to avoid the dreaded 'ExpressionChangedAfterItHasBeenCheckedError'
    setTimeout(() => (this.disableAnimation = false));
  }

  async ngOnInit() {
    this.template.version = Number.isNaN(+this.template.version) ? 1 : +this.template.version + 1;

    this.createTemplateForm = this.fb.group({
      title: [this.template.title, Validators.required],
      description: [this.template.description],
      therapyId: [this.template.therapyId],
      therapyTypeId: [this.template.therapyTypeId],
      dmsFieldmapping: [this.template.dmsFieldmapping],
      dmsDocumentType: [this.template.dmsDocumentType],
      dmsAccessList: [this.template.dmsAccessList],
      dmsModule: [this.template.dmsModule],
      version: [this.template.version],
      payer: [this.template.payer],
      templateTag: [this.template.templateTag],
      entityTypes: [this.template.entityTypes, Validators.required],
      groupingText: [this.template.groupingText],
      sendEmailOnComplete: [this.template.sendEmailOnComplete],
      disableReport: [this.template.disableReport],
      sendToOcr: [this.template.sendToOcr],
    });
    if (this.template._id) {
      this.valid.emit(this.createTemplateForm.valid);
    }
    this._ngUnsubscribe.add(
      this.createTemplateForm.valueChanges.subscribe((value) => {
        this.valid.emit(this.createTemplateForm.valid);
        this.templateChange.emit(Object.assign(this.template, value));
      })
    );
    this.subscribeToAutocomplete();
    await this.loadTemplateTagAndGroupingTexts();
    await this.loadConfig();
  }

  ngOnDestroy() {
    this._ngUnsubscribe.unsubscribe();
  }

  private async loadTemplateTagAndGroupingTexts(): Promise<void> {
    const allTemplates = (await this._templateService.find({})) as Template[];
    if (allTemplates && allTemplates.length) {
      this.allTemplates = Object.keys(groupBy(allTemplates, (item: Template) => item.templateTag)).filter(
        (item) => item !== 'undefined' && item !== 'null'
      );
      this.templateTagOptions = this.allTemplates;

      this.allGroupingTexts = Object.keys(groupBy(allTemplates, (item: Template) => item.groupingText)).filter(
        (item) => item !== 'undefined' && item !== 'null'
      );
      this.groupingTextsOptions = this.allGroupingTexts;
    }
  }

  private subscribeToAutocomplete(): void {
    this._ngUnsubscribe.add(
      this.createTemplateForm.controls['templateTag'].valueChanges.subscribe((searchValue) => {
        this.templateTagOptions = this.allTemplates.filter((item) =>
          item.toLowerCase().includes(searchValue.toLowerCase())
        );
      })
    );
    this._ngUnsubscribe.add(
      this.createTemplateForm.controls['groupingText'].valueChanges.subscribe((searchValue) => {
        this.groupingTextsOptions = this.allGroupingTexts.filter((item) =>
          item.toLowerCase().includes(searchValue.toLowerCase())
        );
      })
    );
  }
  private async loadConfig(): Promise<void> {
    if (!this.config) {
      const configs = (await this.configService.find()) as Config[];
      if (!configs || !configs.length) {
        return null;
      }
      this.config = configs[0];
    }

    if (!this.config.dmsConfig) {
      return;
    }
    this.dmsDocumentTypes = this.config.dmsConfig.dmsDocumentTypes;
    this.dmsAccessLists = this.config.dmsConfig.dmsAccessLists;
    this.dmsFieldMappings = this.config.dmsConfig.dmsFieldMappings;
    this.dmsModules = this.config.dmsConfig.dmsModules;
  }
}
