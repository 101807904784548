import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
export class LoginComponent {
    constructor(_auth, _router, snackBar) {
        this._auth = _auth;
        this._router = _router;
        this.snackBar = snackBar;
        this.username = '';
        this.showSpinner = false;
    }
    ngOnInit() { }
    login() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.snackBar.dismiss();
            try {
                this.showSpinner = true;
                yield this._auth.login(this.username, this.password);
                this.showSpinner = false;
                this._router.navigate(['']);
            }
            catch (e) {
                this.showSpinner = false;
                this.snackBar.open(e.message, undefined, {
                    duration: 3000,
                });
            }
        });
    }
}
