import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  MatAutocompleteModule,
  MatButtonModule,
  MatCheckboxModule,
  MatDialogModule,
  MatIconModule,
  MatInputModule,
  MatListModule,
  MatProgressSpinnerModule,
  MatSidenavModule,
  MatTableModule,
  MatToolbarModule,
  MatTooltipModule,
} from '@angular/material';
import { RouterModule } from '@angular/router';
import { ProgressSpinnerComponent } from 'src/app/shared/layout/spinner-overlay/progress-spinner.component';

import { DeleteDialogComponent } from './layout/delete-dialog/delete-dialog.component';
import { DetailDrawerComponent } from './layout/detail-drawer/detail-drawer.component';
import { FooterComponent } from './layout/footer/footer.component';
import { HeaderComponent } from './layout/header/header.component';
import { ItemSelectorComponent } from './layout/item-selector/item-selector.component';
import { SpinnerComponent } from './layout/spinner/spinner.component';
import { TableRowColumnDirective } from './layout/table/table-row-column.directive';
import { TableComponent } from './layout/table/table.component';
import { UpperFirstPipe } from './pipes/upper-first.pipe';

@NgModule({
  imports: [
    RouterModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatToolbarModule,
    MatListModule,
    MatIconModule,
    MatTableModule,
    MatTooltipModule,
    MatProgressSpinnerModule,
    MatSidenavModule,
    MatCheckboxModule,
    MatAutocompleteModule,
    MatInputModule,
    MatDialogModule,
    MatButtonModule
  ],
  declarations: [
    HeaderComponent,
    FooterComponent,
    SpinnerComponent,
    DetailDrawerComponent,
    TableComponent,
    TableRowColumnDirective,
    UpperFirstPipe,
    ItemSelectorComponent,
    DeleteDialogComponent,
    ProgressSpinnerComponent
  ],
  exports: [
    HeaderComponent,
    FooterComponent,
    SpinnerComponent,
    DetailDrawerComponent,
    TableComponent,
    TableRowColumnDirective,
    UpperFirstPipe,
    ItemSelectorComponent,
    ProgressSpinnerComponent
  ],
  entryComponents: [DeleteDialogComponent]
})
export class SharedModule {}
