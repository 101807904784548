import { TherapyTypeItem } from './therapy-type-item.model';

export class TherapyItem {
  constructor(
    public id: number,
    public displayName: string,
    public abbreviation: string,
    public iconName: string,
    public color: string,
    public secondaryColor: string,
    public therapyTypes: TherapyTypeItem[]
  ) {}
}
