<mat-dialog-content>
  <app-template-basic-info-form
    [(template)]="template"
    [mode]="templateEditorMode"
    (valid)="formIsValid = $event"
  ></app-template-basic-info-form>
</mat-dialog-content>
<p class="error" *ngIf="error">Fehler: {{ error }}</p>
<mat-dialog-actions>
  <button mat-button color="accent" (click)="dialogRef.close()" [disabled]="busy">
    <mat-icon>cancel</mat-icon>Abbrechen
  </button>
  <button mat-button color="primary" (click)="createOrEdit()" [disabled]="!formIsValid || busy || error">
    <mat-icon>save</mat-icon>{{ template._id ? 'Speichern' : 'Hinzufügen' }}
  </button>
</mat-dialog-actions>
