import * as tslib_1 from "tslib";
import { TreeArray } from '../tree-array';
export class EmptyAssetLoader {
    load(audit) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const imagesItems = TreeArray.filterRec(audit.auditNodes, (f) => f.nodeType_Id === 'signature' || f.nodeType_Id === 'image' || f.nodeType_Id === 'picture');
            return imagesItems.map((item) => {
                return {
                    _id: item.nodeType_Id === 'picture' ? item.options : 'mockId',
                    uploadDate: new Date().toISOString(),
                    data: 'mock-data',
                    metadata: {
                        auditNodeId: item.id,
                    },
                };
            });
        });
    }
}
