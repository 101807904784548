<mat-toolbar class="header" [ngClass]="{ fullscreen: fullscreenService.fullscreen | async }">
  <div class="logo"><img src="assets/alberta.svg" /></div>
  <span class="title">Formulare</span>
  <span class="spacer"></span>
  <button class="logout-icon" mat-button (click)="logout()" *ngIf="showLogOutButton">
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 32 32"
      style="enable-background:new 0 0 32 32;"
      xml:space="preserve"
      class="s-ion-icon"
    >
      <g id="Ebene_2"></g>
      <g id="Ebene_1">
        <g id="XMLID_10_">
          <path
            id="XMLID_11_"
            d="M20.6,24.8c-2,1.5-4.3,2.4-6.9,2.4c-6.2,0-11.2-5-11.2-11.2s5-11.2,11.2-11.2c2.5,0,4.9,0.8,6.9,2.4
            C21.2,7.7,22,7.6,22.4,7c0.4-0.6,0.3-1.4-0.2-1.8c-2.4-1.9-5.4-2.9-8.5-2.9C6.2,2.3,0,8.4,0,16s6.2,13.7,13.7,13.7
            c3.1,0,6-1,8.4-2.9c0.6-0.4,0.7-1.2,0.2-1.8C22,24.4,21.1,24.3,20.6,24.8z"
          ></path>
          <path
            id="XMLID_561_"
            d="M32,16C32,16,32,15.9,32,16C32,15.9,32,15.9,32,16c0-0.1,0-0.1,0-0.1c0,0,0,0,0,0c0,0,0,0,0-0.1
            c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0-0.1c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0-0.1c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0-0.1
            c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0l-5.6-6
            c-0.5-0.5-1.3-0.5-1.8-0.1c-0.5,0.5-0.5,1.3-0.1,1.8l3.6,3.9H11.9c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3h15.8L24.1,21
            c-0.5,0.5-0.5,1.3,0,1.8c0.2,0.2,0.6,0.4,0.9,0.4c0.3,0,0.7-0.1,0.9-0.4l5.6-5.9c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
            c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
            c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0C32,16.1,32,16.1,32,16C32,16,32,16,32,16
            C32,16,32,16,32,16C32,16,32,16,32,16z"
          ></path>
        </g>
      </g>
      <g id="Ebene_3"></g>
    </svg>
    <span>Logout</span>
  </button>
</mat-toolbar>
