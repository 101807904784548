<app-detail-drawer #drawer (closedStart)="drawerClosing()">
  <div body>
    <mat-tab-group color="accent" (selectedTabChange)="tabChanged($event)">
      <mat-tab *ngFor="let item of tableConfig" [label]="item.name">
        <ng-container *ngTemplateOutlet="body"></ng-container>
      </mat-tab>
    </mat-tab-group>

    <ng-template #body>
      <div class="button-wrapper">
        <button mat-button (click)="add()" color="primary" [disabled]="drawerOpen">
          <mat-icon>add</mat-icon> Vorlage hinzufügen
        </button>
      </div>

      <mat-form-field>
        <input matInput [(ngModel)]="filter" placeholder="Filter..." [disabled]="drawerOpen" />
      </mat-form-field>

      <div style="width: 100%; display: flex; justify-content: flex-end">
        <mat-slide-toggle (change)="toggleArchived()">Archivierte anzeigen</mat-slide-toggle>
      </div>

      <app-table
        *ngIf="currentTab"
        [dataSource]="dataSource"
        [displayedColumns]="currentTab.columns"
        [filter]="filter"
        (itemSelect)="select($event)"
        [(disableSelection)]="drawerOpen"
      >
        <ng-template appRowColumn="sendEmailOnComplete" appColumnHeader="zuletzt geändert" let-row>
          <mat-icon *ngIf="row.sendEmailOnComplete">email</mat-icon>
        </ng-template>
        <ng-template appRowColumn="status" let-row [appColumnStyle]="{ flex: '0 0 auto', 'width.px': 100 }">
          <div
            [ngClass]="{ draft: row.draft && !row.archived, archived: row.archived, live: !row.draft && !row.archived }"
          >
            {{ row.archived ? 'Archiviert' : row.draft ? 'Entwurf' : 'Live' }}
          </div>
        </ng-template>
        <ng-template appRowColumn="id" let-row>{{ row._id }}</ng-template>
        <ng-template appRowColumn="therapy" let-row>{{ row.therapyId | therapy }}</ng-template>
        <ng-template appRowColumn="updatedAt" appColumnHeader="zuletzt geändert" let-row>{{
          row.updatedAt | updatedAt
        }}</ng-template>
        <ng-template appRowColumn="title" let-row>
          {{ row.title }}
        </ng-template>
        <ng-template appRowColumn="version" let-row>
          {{ row.version }}
        </ng-template>

        <ng-template appRowColumn="entityTypes" appColumnHeader="Institutionstypen" let-row>
          {{ row.entityTypes | entitytype }}
        </ng-template>

        <ng-template appRowColumn="groupingText" appColumnHeader="Gruppierungsebene" let-row>
          {{ row.groupingText }}
        </ng-template>

        <ng-template appRowColumn="templateTag" appColumnHeader="Dokumententyp" let-row>
          {{ row.templateTag }}
        </ng-template>

        <ng-template
          appRowColumn="matchReport"
          appColumnHeader=" "
          let-row
          [appColumnStyle]="{ flex: '0 0 auto', 'width.px': 90 }"
        >
          <mat-icon
            *ngIf="row.match"
            class="margin-right primary-color template-list-button"
            (click)="openMatch($event, row)"
            >compare_arrows</mat-icon
          >
          <mat-icon
            *ngIf="row.report && !row.disableReport"
            class="margin-right primary-color template-list-button"
            (click)="openReport($event, row)"
            >assignment</mat-icon
          >
        </ng-template>
        <ng-template appRowColumn="more" let-row [appColumnStyle]="{ flex: '0 0 auto', 'width.px': 60 }">
          <app-template-more-menu
            [template]="row"
            (update)="update($event)"
            (archivedToggled)="archivedToggled($event, row)"
          ></app-template-more-menu>
        </ng-template>
      </app-table>
      <mat-spinner *ngIf="showSpinner" style="margin: 100px auto"></mat-spinner>
    </ng-template>
  </div>
  <div detail class="detail">
    <app-template-detail
      [template]="selectedTemplate"
      [sidenav]="drawer"
      (updated)="update($event)"
      #detail
    ></app-template-detail>
  </div>
</app-detail-drawer>
