import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';
export class TherapyComponent {
    constructor(therapyService) {
        this.therapyService = therapyService;
        this.therapies = [];
    }
    ngOnInit() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.therapies = yield this.therapyService.getTherapyItems();
        });
    }
    writeValue(obj) {
        this.select.value = obj;
    }
    registerOnChange(fn) {
        this.select.registerOnChange(fn);
    }
    registerOnTouched(fn) {
        this.select.registerOnTouched(fn);
    }
    setDisabledState(isDisabled) {
        this.select.disabled = isDisabled;
    }
}
